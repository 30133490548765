import React, { memo, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { userGrantSelector, selectedPlatformSelector } from '../../../redux/selectors'
import { SOCIAL_PLATFORMS_NAMES, USER_INDEXES } from '../../../constants/appSettings'

import SearchbarContainer from './searchSidebar/SearchbarContainer'
import CollectionsBarContainer from './collectionsSidebar/CollectionsBarContainer'
import CampaignsSidebarContainer from './campaignsSidebar/CampaignsSidebarContainer'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { SidebarOuterWrapper } from '../../../components/common/styledWrappers'

const SidebarContainer = ({
  withSearch,
  withCollections,
  fullSidebar,
  withCampaigns,
  onChangeSearchType,
  searchType,
  onHandleOpenViewType,
  currentOpenSearchParam,
  setCurrentOpenSearchParam
}) => {
  const {
    enable_collections,
    enable_tracking,
    enable_tt_search,
    enable_ig_search,
    enable_yt_search,
  } = useSelector(userGrantSelector, shallowEqual)
  const currentSocialPlatform = useSelector(selectedPlatformSelector, shallowEqual)

  const { pathname } = useLocation()
  const isCollectionPage = pathname?.includes('/collections')
  const isSearchPage = pathname?.includes('/search')

  //for collections navbar
  const [isMinimalNav, setIsMinimalNav] = useLocalStorage('isMinimalNavCollections', false)
  const [navType, setNavType] = useState('default')

  useEffect(() => {
    if (isMinimalNav && isCollectionPage) {
      setNavType(isMinimalNav === 'false' || isMinimalNav === false ? 'default' : 'min')
    } else {
      setNavType('default')
    }
  }, [isMinimalNav, isCollectionPage])

  const isEnableSearch = useMemo(() => {
    return [enable_tt_search, enable_ig_search, enable_yt_search].some(Boolean)
  }, [enable_tt_search, enable_ig_search, enable_yt_search])

  const renderMap = useMemo(
    () => ({
      search: fullSidebar || withSearch,
      collections: fullSidebar || withCollections,
      campaigns: fullSidebar || withCampaigns,
    }),
    []
  )

  const currentPlatformIndex = useMemo(() => {
    if (renderMap.search && !isEnableSearch) return ''
    if (currentSocialPlatform === SOCIAL_PLATFORMS_NAMES.tiktok.name) return USER_INDEXES.tiktok
    if (currentSocialPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name) return USER_INDEXES.youtube
    if (currentSocialPlatform === SOCIAL_PLATFORMS_NAMES.twitch.name) return USER_INDEXES.twitch
    return USER_INDEXES.instagram
  }, [currentSocialPlatform, isEnableSearch])

  const onChangeNavType = () => {
    const value = isMinimalNav === 'false' || isMinimalNav === false ? true : false
    setIsMinimalNav(value)
    setNavType(value ? 'min' : 'default')
  }

  return (
    <>
      <SidebarOuterWrapper
        userindex={currentPlatformIndex}
        isSearch={renderMap.search}
        isCollections={renderMap.collections}
        isNavType={navType}
        isSearchPage={isSearchPage}
      >
        {renderMap.search && (
          <>
            <SearchbarContainer
              isSearchEnabled={isEnableSearch}
              userindex={currentPlatformIndex}
              searchType={searchType}
              onChangeSearchType={onChangeSearchType}
              currentOpenSearchParam={currentOpenSearchParam}
              setCurrentOpenSearchParam={setCurrentOpenSearchParam}
            />
            {enable_collections && (
              <CollectionsBarContainer
                useAsPopupWithDragDrop
                allowCollections={enable_collections}
              />
            )}
          </>
        )}

        {renderMap.collections && (
          <CollectionsBarContainer
            allowCollections={enable_collections}
            navType={navType}
            onChangeNavType={onChangeNavType}
            onHandleOpenViewType={onHandleOpenViewType}
          />
        )}

        {renderMap.campaigns && <CampaignsSidebarContainer disabled={!enable_tracking} />}
      </SidebarOuterWrapper>
    </>
  )
}

SidebarContainer.propTypes = {
  withCollections: PropTypes.bool,
  withSearch: PropTypes.bool,
  fullSidebar: PropTypes.bool,
  withCampaigns: PropTypes.bool,
  onChangeSearchType: PropTypes.func,
  searchType: PropTypes.string,
  onHandleOpenViewType: PropTypes.func,
  currentOpenSearchParam: PropTypes.string,
  setCurrentOpenSearchParam: PropTypes.func
}

export default memo(SidebarContainer)
