import React, { useEffect, useMemo, useState } from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  Slider,
  Stack,
} from '@mui/material'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../../constants/appSettings'
import { searchContentTypes } from '../../../../../constants/search'
import { pretifyBigNum } from '../../../../../utils'
import {
  defaultRangeValues,
  extractValues,
  getValuesFromQuery,
  marksAmount,
  scale,
  unscale,
} from '../utils'
import { infludataThemes } from '../../../../../themes/infludataThemes'

export const AmountFilter = props => {
  const { selectedPlatform, query, handlersMap, isModalOpen, searchType } = props || {}

  const { labelStrings } = useTranslation()

  const creatorIgOptions = [
    { value: 'followers', label: labelStrings.followers },
    { value: 'views', label: labelStrings.averageReach },
    { value: 'playsPerReel', label: labelStrings.playsPerReel },
  ]
  const contentOptions = [{ value: 'followers', label: labelStrings.followers }]
  const creatorTtOptions = [
    { value: 'followers', label: labelStrings.followers },
    { value: 'plays', label: labelStrings.playsWithK },
  ]
  const creatorYtOptions = [
    { value: 'followers', label: labelStrings.subscribers },
    { value: 'youTubeShorts', label: labelStrings.shortsViews },
    { value: 'youtubeVideos', label: labelStrings.videoViews },
  ]
  const creatorTwOptions = [{ value: 'followers', label: labelStrings.subscribers }]

  const optionCreatorsValues = {
    [SOCIAL_PLATFORMS_NAMES.instagram.name]: creatorIgOptions,
    [SOCIAL_PLATFORMS_NAMES.tiktok.name]: creatorTtOptions,
    [SOCIAL_PLATFORMS_NAMES.youtube.name]: creatorYtOptions,
    [SOCIAL_PLATFORMS_NAMES.twitch.name]: creatorTwOptions,
  }

  const [open, setOpen] = useState(false)

  const optionsData = useMemo(() => {
    return searchType === searchContentTypes.CREATOR
      ? optionCreatorsValues[selectedPlatform]
      : contentOptions
  }, [searchType, selectedPlatform])

  const [selectedFollowersType, setSelectedFollowersType] = useState(optionsData[0]?.value)

  const querySliderValues = extractValues(query, getValuesFromQuery)
  const currentSliderValueFromQuery = querySliderValues[selectedFollowersType]

  const onChangeSelectType = event => {
    const {
      target: { value },
    } = event || {}
    setSelectedFollowersType(value)
  }

  const [localSliderValue, setLocalSliderValue] = useState([
    scale(defaultRangeValues.min),
    scale(defaultRangeValues.max),
  ])

  const handleChange = (event, newValue) => {
    setLocalSliderValue(newValue)
  }

  const onChangeQueryValues = (e, values) => {
    const [min, max] = values
    handlersMap[selectedFollowersType]([
      scale(min),
      isNaN(scale(max)) || scale(max) > 8000000 ? Infinity : scale(max),
    ])
  }

  useEffect(() => {
    if (currentSliderValueFromQuery?.length) {
      setLocalSliderValue([
        unscale(currentSliderValueFromQuery[0]),
        currentSliderValueFromQuery[1] === Infinity || !currentSliderValueFromQuery[1]
          ? defaultRangeValues.max
          : unscale(currentSliderValueFromQuery[1]),
      ])
    } else {
      setLocalSliderValue([defaultRangeValues.min, defaultRangeValues.max])
    }
  }, [currentSliderValueFromQuery?.length, selectedFollowersType])

  useEffect(() => {
    const getNames = Object.keys(querySliderValues)?.filter(key => querySliderValues[key][0] > 2000)
    const getActiveOption = optionsData?.filter(el => getNames?.includes(el.value))

    if (getActiveOption?.length) {
      setSelectedFollowersType(getActiveOption[0]?.value)
    }
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isModalOpen) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }, 200)

    return () => clearTimeout(timer)
  }, [isModalOpen])

  return (
    <Box sx={{ height: '120px' }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <FormControl
          sx={{
            width: 250,
            '& .MuiOutlinedInput-root': {
              outline: 'unset',
              '& fieldset': {
                borderColor: '#BC9797',
              },
              '&:hover fieldset': {
                borderColor: '#784E4E',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#784E4E',
              },
            },
          }}
        >
          <InputLabel
            size="small"
            sx={{
              color: '#784E4E',
              fontWeight: 'bold',
              '&.Mui-focused': {
                color: '#784E4E',
                fontWeight: 'bold',
              },
            }}
          >
            {labelStrings.pleaseSelectCategories}
          </InputLabel>
          <Select
            size="small"
            open={open}
            onClose={handleClose}
            onOpen={() => setOpen(true)}
            value={selectedFollowersType}
            onChange={onChangeSelectType}
            input={<OutlinedInput label={labelStrings.pleaseSelectCategories} />}
            sx={{
              color: '#784E4E',
              fontWeight: 'bold',
              fontFamily: 'Inter',
              '& .MuiSvgIcon-root': {
                '& .MuiSelect-icon': {
                  color: '#784E4E',
                },
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 250,
                  color: '#784E4E',
                },
              },
            }}
          >
            {optionsData?.map(category => (
              <MenuItem
                key={category?.value}
                value={category?.value}
                sx={{ fontSize: '12px', fontWeight: 700, fontFamily: 'Inter' }}
              >
                {category?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: '80px' }}>
          <Chip
            size="small"
            label={`${pretifyBigNum(scale(localSliderValue[0]), 0)} - ${
              scale(localSliderValue[1]) > 8000000 || isNaN(scale(localSliderValue[1]))
                ? '∞'
                : pretifyBigNum(scale(localSliderValue[1]), 0)
            }`}
            sx={{
              backgroundColor: infludataThemes[selectedPlatform]?.platformColor,
              color: 'white',
              fontWeight: 'bold',
            }}
          />
        </Stack>
      </Stack>

      <Box sx={{ px: 2, pt: 2 }}>
        <Slider
          sx={{
            height: '5px',
            borderRadius: '8px',
            color: infludataThemes[selectedPlatform]?.platformColor,
            '& .MuiSlider-markLabel': {
              fontSize: '10px',
              color: infludataThemes[selectedPlatform]?.platformColor,
              fontWeight: 'bold',
            },
          }}
          value={localSliderValue}
          onChange={handleChange}
          onChangeCommitted={onChangeQueryValues}
          valueLabelDisplay="off"
          valueLabelFormat={val => (val > 8000000 || isNaN(val) ? '∞' : pretifyBigNum(val))}
          min={defaultRangeValues.min}
          max={defaultRangeValues.max}
          marks={marksAmount}
          scale={scale}
        />
      </Box>
    </Box>
  )
}
