import React, { useEffect, useState } from 'react'
import { Stack, Typography, Box, Chip, Avatar } from '@mui/material'
import useTranslation from '../../../../localization/useTranslation'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import AddCreatorsTo from '../../../components/add-creator'

export const StepThree = props => {
  const {
    setValue,
    getValues,
    creatorPlatform,
    creatorsMention,
    setCreatorPlatform,
    setCreatorsMention,
  } = props

  const { labelStrings } = useTranslation()

  const [creators, setCreators] = useState([])
  const creatorsIds = creators?.map(creator => creator?._id)

  const valueFromFormState = getValues('trackedMetrics')

  const addCreator = async creator => {
    const updatedValue = [...creators, creator]
    setCreators(updatedValue)
    setValue('trackedMetrics', updatedValue)
  }

  const removeCreator = (creatorId, username) => {
    const updatedValue = creators?.filter(creator => creator?._id !== creatorId)
    const updatedMentionValue = creatorsMention?.filter(mention => mention !== username)
    setCreators(updatedValue)
    setValue('trackedMetrics', updatedValue)
    setCreatorsMention(updatedMentionValue)
  }

  const onAddCreatorsMention = mention => {
    if (!creatorsMention?.includes(mention)) {
      setCreatorsMention(prev => [...prev, mention])
    }
  }

  useEffect(() => {
    setCreators(valueFromFormState)
  }, [valueFromFormState?.length])

  useEffect(() => {
    if (
      creators?.length &&
      (creators[0]?.index && creators?.every(el => el?._index !== creatorPlatform?.toLocaleLowerCase()))
    ) {
      setCreatorsMention([])
      setCreators([])
    }
  }, [creators, creatorPlatform])

  return (
    <Stack alignItems="center">
      <Typography
        component="label"
        sx={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px', mb: 1 }}
      >
        {labelStrings.createCampaign.steps.three.label}
      </Typography>

      <Box sx={{ width: '464px', py: 2 }}>
        <AddCreatorsTo
          isAllowedToAddCreators={true}
          creatorIdFromState={creatorsIds}
          setCreatorIdFromState={addCreator}
          setCreatorPlatform={setCreatorPlatform}
          setCreatorsMention={onAddCreatorsMention}
          isSetCreatorId={false}
        />
      </Box>
      <Scrollbar
        sx={{
          maxHeight: '250px',
          width: '525px',
        }}
      >
        <Stack
          direction="row"
          sx={{ width: '100%', flexWrap: 'wrap', gap: 1, px: 3.5 }}
          justifyContent="center"
        >
          {creators?.map(creator => (
            <Chip
              sx={{
                borderColor: 'transparent',
                backgroundColor: '#F4F6F8',
                py: 3.5,
                px: 1,
                width: 'fit-content',
                fontWeight: 'bold',
                '.MuiChip-avatar': { width: '36px', height: '36px', mr: 0.5 },
              }}
              key={creator._id || creator.values}
              avatar={<Avatar alt={creator?.displayName} src={creator?.profilePicURL} />}
              label={`@${creator?.username || creator.value}`}
              variant="outlined"
              onDelete={() => removeCreator(creator._id, creator?.username)}
            />
          ))}
        </Stack>
      </Scrollbar>
    </Stack>
  )
}
