import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Card, Table, TableBody, TableContainer, Paper } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from '../../../components/table'
import { TableRowsLoader } from '../../../components/table/TableRowSkeleton'
import useTranslation from '../../../../localization/useTranslation'
import { SearchTableRow } from './SearchTableRow'
import { QuickPreview } from './QuickPreview'
import AddToCampaignPipelineModal from '../../../../components/campaigns/campaignTools/OverviewTools/AddToCampaignPipelineModal'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'
import { getWidth } from '../../../../hooks/useGetWidth'
import { collectionsSelector } from '../../../../redux/selectors'
import { shallowEqual, useSelector } from 'react-redux'
import EditCollectionModal from '../../../../components/collections/collectionTools/EditCollectionModal'

export const SearchTableContainer = props => {
  const {
    openModal,
    setEditItem,
    data,
    isLoading,
    onRemove,
    setCurrPage = () => {},
    onBlockUnblockUser,
    currentPlatform,
    currentQuery,
    isLookalike,
    isLookaLikeList,
    setSearchUsers,
    countResults,
    searchType
  } = props

  const { labelStrings } = useTranslation()

  const collectionsList = useSelector(collectionsSelector, shallowEqual)

  const tableRef = useRef()
  const tableWidth = getWidth(tableRef)
  const initialTableWidth = tableRef?.current?.offsetWidth

  const TABLE_HEAD = [
    {
      id: 'profileScore',
      label: 'Quality',
      align: 'center',
      width: 'fit-content',
    },
    {
      id: 'username',
      label: 'Name, Username, Country',
      align: 'left',
      width: 'fit-content',
    },
    {
      id: 'followers',
      label:
        currentPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name
          ? labelStrings.subscribers
          : labelStrings.followers,
      align: 'left',
      width: 'fit-content',
    },
    ...(currentPlatform !== SOCIAL_PLATFORMS_NAMES.twitch.name
      ? [
          {
            id: 'engagementRate',
            label: `ER`,
            align: 'center',
            minWidth: '80px',
            width: 'fit-content',
          },
        ]
      : []),
    {
      id: 'bio',
      label:
        currentPlatform === SOCIAL_PLATFORMS_NAMES.twitch.name ? labelStrings.description : `Bio`,
      align: 'left',
      width: 'fit-content',
    },
    ...(isLookaLikeList
      ? [
          {
            id: 'matchRate',
            label: labelStrings.matchRate,
            align: 'center',
            minWidth: '120px',
            width: 'fit-content',
          },
        ]
      : []),
    { id: '' },
    //{ id: "", label: "Preview", align: "center", width: 'fit-content' },
  ]

  const { dense, page, rowsPerPage } = useTable({ defaultRowsPerPage: isLookalike ? 100 : 25 })

  const [isOpenedPipelineModal, setIsOpenPipelineModal] = useState(false)
  const [expandCollectionsList, setExpandCollectionsList] = useState('')

  const [isOpenQuickPreview, setIsOpenQuickPreview] = useState(false)
  const [quickPreviewAnchorEl, setQuickPreviewAnchorEl] = useState(null)
  const [currentDataForQuickPreview, setCurrentDataForQuickPreview] = useState(null)

  const [
    isCreateNewCollectionWithCurrentProfile,
    setIsCreateCollectionWithCurrentProfile,
  ] = useState(null)

  const denseHeight = dense ? 52 : 72
  const isNotFound = !data?.length && !isLoading

  const handleDeleteRow = metricId => {
    onRemove({ metricId })
  }

  const handleEditRow = row => {
    setEditItem(row)
    openModal(true)
  }

  useEffect(() => {
    setCurrPage(page)
  }, [page])

  const handleCloseQuickPreview = ({ e, isPipelineModal = false }) => {
    if (!isPipelineModal) {
      setQuickPreviewAnchorEl(null)
      setCurrentDataForQuickPreview(null)
    }
    setIsOpenQuickPreview(false)
  }

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <TableContainer
              component={Paper}
              ref={tableRef}
              sx={{
                position: 'relative',
                overflow: 'unset',
              }}
            >
              <Table
                size={dense ? 'small' : 'medium'}
                sx={{
                  minWidth: 800,
                  '& .main-search-row': {
                    '&:hover': {
                      backgroundColor: '#FFF5F5 !important',
                    },
                  },
                  [`& .${tableCellClasses.root}`]: {
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  },
                  '& .MuiTableHead-root': {
                    '& .MuiTableCell-root': {
                      position: 'sticky',
                      top: 0,
                      zIndex: 9999,
                      backgroundColor: `#FFFAFA !important`,
                      backgroundImage: 'none',
                      color: '#AD9090',
                      '& .MuiTableSortLabel-root': {
                        textTransform: 'uppercase',
                        fontSize: '10px',
                        fontWeight: 700,
                        fontFamily: 'Inter',
                      },
                    },
                  },
                }}
              >
                <TableHeadCustom
                  headLabel={TABLE_HEAD}
                  rowCount={data?.length}
                  currentPlatform={currentPlatform}
                />

                <TableBody sx={{ width: '1000px', overflowY: 'hidden' }}>
                  {isLoading ? (
                    <TableRowsLoader rowsNum={7} thNum={5} />
                  ) : (
                    !isLoading &&
                    data
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <SearchTableRow
                          key={row._id}
                          row={row}
                          onDeleteRow={() => handleDeleteRow(row._id)}
                          onEditRow={() => handleEditRow(row)}
                          setIsOpenQuickPreview={setIsOpenQuickPreview}
                          setQuickPreviewAnchorEl={setQuickPreviewAnchorEl}
                          setCurrentDataForQuickPreview={setCurrentDataForQuickPreview}
                          currentQuery={currentQuery}
                          tableWidth={tableWidth}
                          initialTableWidth={initialTableWidth}
                          isLookaLikeList={isLookaLikeList}
                          collectionsList={collectionsList}
                          expandCollectionsList={expandCollectionsList}
                          setExpandCollectionsList={setExpandCollectionsList}
                          setSearchUsers={setSearchUsers}
                          usersData={data}
                          countResults={countResults}
                          setIsCreateCollectionWithCurrentProfile={
                            setIsCreateCollectionWithCurrentProfile
                          }
                          handleClose={handleCloseQuickPreview}
                          onBlockUnblockUser={onBlockUnblockUser}
                          searchType={searchType}
                          currentPlatform={currentPlatform}
                        />
                      ))
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, data?.length)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>

      <QuickPreview
        data={currentDataForQuickPreview}
        isOpenQuickPreview={isOpenQuickPreview}
        quickPreviewAnchorEl={quickPreviewAnchorEl}
        handleClose={handleCloseQuickPreview}
        onBlockUnblockUser={onBlockUnblockUser}
        setIsOpenPipelineModal={setIsOpenPipelineModal}
      />

      <AddToCampaignPipelineModal
        profile={currentDataForQuickPreview}
        platform={currentDataForQuickPreview?._index || ''}
        isOpened={isOpenedPipelineModal}
        closeModal={e => {
          e.preventDefault()
          e.stopPropagation()
          setIsOpenPipelineModal(false)
        }}
        disableAutoFocus
        disableEnforceFocus
      />

      <EditCollectionModal
        isOpened={isCreateNewCollectionWithCurrentProfile?._id}
        platform={isCreateNewCollectionWithCurrentProfile?._index}
        closeModal={e => {
          e.preventDefault()
          e.stopPropagation()
          setIsCreateCollectionWithCurrentProfile(null)
        }}
        profile={isCreateNewCollectionWithCurrentProfile || {}}
        isNewCollection
        withoutPortal
        source='search'
      />
    </>
  )
}

SearchTableContainer.propTypes = {
  openModal: PropTypes.func,
  setEditField: PropTypes.func,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
  setCurrPage: PropTypes.func,
  onBlockUnblockUser: PropTypes.func,
  currentPlatform: PropTypes.string,
  currentQuery: PropTypes.object,
  isLookalike: PropTypes.bool,
  isLookaLikeList: PropTypes.bool,
  setSearchUsers: PropTypes.func,
  usersData: PropTypes.array,
  countResults: PropTypes.number,
  searchType: PropTypes.string
}
