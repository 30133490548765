import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Paper,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import ProfileAvatar from '../../components/profileCommon/profileAvatar'
import RelativePortal from 'react-relative-portal'
import {
  AdditionalFieldDetailsOverlayForDashboard,
  ProfileImagesWrapper,
  ProfileImgWrapper,
} from '../../components/profileCommon/profileListTable/tableStyles'
import LazyImage from '../../components/lazyImage'
import { constructLinkToWECFileServer } from '../../utils'
import { AddProfileToCollection } from '../pages/Search/Table/AddProfileToCollection'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { useDispatch } from 'react-redux'
import {
  addElementToCollection,
  removeElementFromCollection,
} from '../../redux/ducks/collectionsDucks'
import useTranslation from '../../localization/useTranslation'
import { CreatorsLikeBasedTools } from './CreatorsLikeBasedTools'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { setLastPageBeforeProfileRedirect } from '../../redux/ducks/appSettingsDucks'

const DollarSign = styled(Box)(({ theme, filled }) => ({
  fontSize: '16px',
  fontWeight: 600,
  color: filled ? '#13EB9D' : '#FBEBEB',
  margin: theme.spacing(0.5),
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7,
  },
}))

const PriceIndicator = ({ filledSigns }) => {
  const priceMessages = {
    [0]: 'Less than $200',
    [1]: 'Less than $500',
    [2]: 'Less than $1000',
    [3]: 'Less than $5000',
    [4]: 'Less than $10000',
    [5]: 'More than $10000',
  }

  const totalSigns = 6
  return (
    <Box sx={{ display: 'flex' }}>
      {Array.from({ length: totalSigns }, (_, i) => (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#FFF1F1',
                boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
                p: 0,
              },
            },
          }}
          title={
            <Typography
              sx={{
                p: 1,
                fontFamily: 'Inter',
                fontSize: '14px',
                color: '#6B4545',
                fontWeight: 500,
              }}
            >
              {priceMessages[i]}
            </Typography>
          }
        >
          <DollarSign key={i} filled={i < filledSigns}>
            $
          </DollarSign>
        </Tooltip>
      ))}
    </Box>
  )
}

export const CreatorLikesBasedItem = props => {
  const {
    creator,
    canAddToFavs,
    collectionsList,
    enable_collections,
    isUserAuthenticated,
    organizationCurrency,
    removeCreator,
    userdata,
    updateCreators,
  } = props || {}

  const { flags, labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const modalRef = useRef(null)
  const history = useHistory()

  const [openModal, setOpenModal] = useState(false)
  const [expandCollectionsList, setExpandCollectionsList] = useState('')

  const currencySymbol = {
    ['usd']: `$`,
    ['eur']: `€`,
    ['gbp']: `£`,
  }

  const addToFavs = userId => {
    // @ts-ignore
    return dispatch(addElementToCollection(userId, 'fav', null, 'dashboard'))
  }

  const removeFromFavs = userId => {
    // @ts-ignore
    return dispatch(removeElementFromCollection(userId, 'fav', '', true))
  }

  const onOpenProfile = profileId => {
    dispatch(setLastPageBeforeProfileRedirect('dashboard'))
    const profileLink = `/profile/${profileId}`
    history.push(profileLink)
  }

  return (
    <Paper
      sx={{
        boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
        borderRadius: '18px',
        width: '168px',
        position: 'relative',
      }}
      key={creator?._id}
      ref={modalRef}
    >
      <Stack>
        <Stack sx={{ cursor: 'pointer' }} onClick={() => onOpenProfile(creator?._id)}>
          <Stack alignItems="center" sx={{ p: 2, pb: 1 }}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
                userSelect: 'none',
                '&:hover': {
                  '.profile-avatar_container .overlay .overlay-inner': {
                    opacity: 1,
                  },
                },
              }}
            >
              <Box onMouseOver={() => setOpenModal(true)} onMouseLeave={() => setOpenModal(false)}>
                <ProfileAvatar
                  score={creator?.profileScore?.overall}
                  profilePicUrl={creator?.profilePicURL}
                  userindex={creator?._index || 'instagram'}
                  withRating={true}
                  withOverlay={true}
                  size={70}
                />
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: '160px', position: 'absolute', top: `-12px` }}
              >
                <IconButton
                  onClick={e => removeCreator(e, creator?._id)}
                  size="small"
                  sx={{ color: '#6B4545' }}
                >
                  <CloseRoundedIcon />
                </IconButton>
                <IconButton size="small" sx={{ color: '#6B4545' }}>
                  <SearchRoundedIcon />
                </IconButton>
              </Stack>
            </Box>
            <Stack spacing={0} alignItems="center" sx={{ mt: 2 }}>
              {creator?.displayName && (
                <Typography
                  noWrap
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '11px',
                    color: '#6B4545',
                    textAlign: 'center',
                    fontWeight: 700,
                    lineHeight: 'initial',
                    maxWidth: '124px',
                  }}
                >
                  {creator?.displayName}
                </Typography>
              )}
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: '#FFF1F1',
                      boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
                    },
                  },
                }}
                title={creator?.description || ''}
                arrow
              >
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '11px',
                    color: '#6B4545',
                    textAlign: 'center',

                    fontWeight: 300,
                    lineHeight: 'initial',
                  }}
                >
                  {creator?.description?.slice(0, 40) + '...'}
                </Typography>
              </Tooltip>
            </Stack>

            <Chip
              size="small"
              sx={{
                my: 0.5,
                height: '16px',
                backgroundColor: '#FAEBEB',
                fontFamily: 'Inter',
                fontSize: '11px',
                color: '#6B4545',
              }}
              label={creator?.categories?.length ? creator?.categories[0]?.category : ''}
            />
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '11px',
                color: '#6B4545',
                textAlign: 'center',
                fontWeight: 700,
                lineHeight: 'initial',
              }}
            >
              {flags[creator?.country] ? flags[creator?.country] : creator?.country}{' '}
              {creator?.country || '-'}
            </Typography>
          </Stack>
          {creator?.cpms?.averagePrice && (
            <>
              <Stack alignItems="center">
                <PriceIndicator filledSigns={creator?.cpms?.stepLevel} />
              </Stack>
              {/* <Divider sx={{ width: '100%', borderColor: '#FFF1F1', mt: 0.5 }} /> */}

              <Typography
                sx={{
                  p: 0.1,
                  color: '#6B4545',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontSize: '10px',
                }}
              >
                {`Avg. ${
                  organizationCurrency ? currencySymbol[organizationCurrency.toLowerCase()] : '$'
                }${creator?.cpms?.averagePrice || 0} per content`}
              </Typography>
            </>
          )}

          <Stack
            sx={{
              background: 'rgba(255, 245, 245, 0.50)',
              borderTop: '1px solid #FFF1F1',
            }}
          >
            <Stack sx={{ borderBottom: '1px solid #FFF1F1' }}>
              {!!creator?.mentions?.length && (
                <>
                  <Typography
                    sx={{
                      p: 1,
                      color: '#6B4545',
                      textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '10px',
                      fontWeight: '700',
                    }}
                  >
                    {labelStrings.workedWith}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={0.5}
                    sx={{ pb: 1 }}
                  >
                    {creator?.mentions?.map((el, idx) => {
                      if (idx > 4) return
                      return (
                        <Tooltip
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#FFF1F1',
                                boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
                                p: 0,
                              },
                            },
                          }}
                          title={
                            <Stack spacing={0.5} sx={{ p: 1 }}>
                              <Typography
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '14px',
                                  color: '#6B4545',
                                  fontWeight: 500,
                                  lineHeight: 'initial',
                                }}
                              >
                                @{el?.mention}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '14px',
                                  color: '#6B4545',
                                  fontWeight: 400,
                                  lineHeight: 'initial',
                                }}
                              >
                                {el?.displayName}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '14px',
                                  color: '#6B4545',
                                  fontWeight: 400,
                                  lineHeight: 'initial',
                                }}
                              >
                                {labelStrings?.mentions}:{' '}
                                {Math.round(el?.storycount + el?.postcount + el?.reelcount) || 0}
                              </Typography>
                            </Stack>
                          }
                        >
                          <Avatar
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                            sx={{
                              width: '24px',
                              height: '24px',
                              cursor: 'pointer',
                              '&:hover': {
                                opacity: 0.7,
                              },
                            }}
                            sizes="24"
                            alt={el.displayName}
                            key={el.instagramId}
                            src={el.imageUrl}
                          />
                        </Tooltip>
                      )
                    })}
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }}>
          <CreatorsLikeBasedTools
            isFav={creator?.isFav}
            userindex={creator?._index}
            removeFromFavs={() => removeFromFavs(creator?._id)}
            addToFavs={() => addToFavs(creator?._id)}
            enableFavorites={enable_collections}
            canAddToFavs={canAddToFavs}
            isUserAuthenticated={isUserAuthenticated}
            tipPosition="top left"
          />
          <AddProfileToCollection
            row={creator}
            collectionsList={collectionsList}
            isExpandedCollectionsList={expandCollectionsList === creator._id}
            setExpandCollectionsList={setExpandCollectionsList}
            setSearchUsers={updateCreators}
            usersData={userdata}
            countResults={100}
            setIsCreateCollectionWithCurrentProfile={() => {}}
            isSearch
            source="dashboard"
          />
        </Stack>
      </Stack>
      {openModal && !!creator?.postLinksArray?.length && (
        <RelativePortal component="div">
          <AdditionalFieldDetailsOverlayForDashboard>
            <ProfileImagesWrapper>
              {creator?.postLinksArray?.map((el, index) => (
                <ProfileImgWrapper key={index}>
                  <LazyImage key={index} src={constructLinkToWECFileServer(el)} alt="" />
                </ProfileImgWrapper>
              ))}
            </ProfileImagesWrapper>
          </AdditionalFieldDetailsOverlayForDashboard>
        </RelativePortal>
      )}
    </Paper>
  )
}
