import React from 'react'
import { Box } from '@mui/material'
import { SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'
import {
  StyledSocialPlatformTab,
  StyledSocialPlatformTabs,
} from '../nav-section/CreatorsSuggestions/styles'
import { InstagramSwitchIcon, TikTokSwitchIcon, TwitchSwitchIcon, YouTubeSwitchIcon } from '../nav-section/CreatorsSuggestions/icons'

export const PlatformSwitcher = ({ setPlatform, platformValue }) => {
  const onChangeSocialTabs = (e, value) => {
    if (value === platformValue) return
    setPlatform(value)
  }

  return (
    <Box sx={{ backgroundColor: '#FDFCFF', borderRadius: '10px', padding: '3px', zIndex: 1 }}>
      <StyledSocialPlatformTabs value={platformValue} onChange={onChangeSocialTabs}>
        <StyledSocialPlatformTab
          disabled={false}
          sx={{ marginRight: '5px !important' }}
          current_tab={platformValue}
          is_active={`${platformValue === SOCIAL_PLATFORMS_NAMES.instagram.name}`}
          aria-label="Instagram"
          value={SOCIAL_PLATFORMS_NAMES.instagram.name}
          icon={
            <InstagramSwitchIcon
              sx={{ fontSize: 22 }}
              color={platformValue === SOCIAL_PLATFORMS_NAMES.instagram.name ? 'white' : '#A06E6E'}
            />
          }
        />
        <StyledSocialPlatformTab
          disabled={false}
          sx={{ marginRight: '5px !important' }}
          current_tab={platformValue}
          is_active={`${platformValue === SOCIAL_PLATFORMS_NAMES.tiktok.name}`}
          aria-label="TikTok"
          value={SOCIAL_PLATFORMS_NAMES.tiktok.name}
          icon={
            <TikTokSwitchIcon
              sx={{ fontSize: 18 }}
              color={platformValue === SOCIAL_PLATFORMS_NAMES.tiktok.name ? 'white' : '#A06E6E'}
            />
          }
        />
        <StyledSocialPlatformTab
          disabled={false}
          sx={{ marginRight: '5px !important' }}
          current_tab={platformValue}
          is_active={`${platformValue === SOCIAL_PLATFORMS_NAMES.youtube.name}`}
          aria-label="YouTube"
          value={SOCIAL_PLATFORMS_NAMES.youtube.name}
          icon={
            <YouTubeSwitchIcon
              sx={{ fontSize: 22 }}
              color={platformValue === SOCIAL_PLATFORMS_NAMES.youtube.name ? 'white' : '#A06E6E'}
            />
          }
        />

        <StyledSocialPlatformTab
          disabled={false}
          sx={{ marginRight: '0 !important' }}
          current_tab={platformValue}
          is_active={`${platformValue === SOCIAL_PLATFORMS_NAMES.twitch.name}`}
          aria-label="YouTube"
          value={SOCIAL_PLATFORMS_NAMES.twitch.name}
          icon={
            <TwitchSwitchIcon
              sx={{ fontSize: 22 }}
              color={platformValue === SOCIAL_PLATFORMS_NAMES.twitch.name ? 'white' : '#A06E6E'}
            />
          }
        />
      </StyledSocialPlatformTabs>
    </Box>
  )
}
