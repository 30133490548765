import { SvgIcon } from '@mui/material'
import React from 'react'

export const YouTubeSwitchIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        stroke="white"
        strokeWidth={1}
        d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"
      />
      <path d="m9.75 15.02 5.75-3.27-5.75-3.27v6.54z" fill="white" />
    </SvgIcon>
  )
}
export const TikTokSwitchIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
    </SvgIcon>
  )
}
export const InstagramSwitchIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z" />
    </SvgIcon>
  )
}

export const TwitchSwitchIcon = ({ height = '20px', width = '20px', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="tw-platform-icon"
      width={width}
      height={height}
      viewBox='0 0 16 16'
      {...props}
    >
      <path
        fill="#fff"
        d="M12.219 3.25v3.438H11V3.25h1.219Zm-3.313-.031v3.437H7.687V3.22h1.22ZM3.75 0h11.469v8L9.78 13.156H7.375l-3 2.844v-2.844H.75V2.875L3.75 0Zm10.281 7.438V1.155H4.375V9.72h2.719v2l2.093-2h2.407l2.437-2.281Z"
      />
    </svg>
  )
}
