import {
  Card,
  ListItem,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'

export const bgGradients = {
  [SOCIAL_PLATFORMS_NAMES.instagram.name]: 'linear-gradient(90deg, #FFC300 0.35%, #F50 100%)',
  [SOCIAL_PLATFORMS_NAMES.tiktok.name]: 'linear-gradient(90deg, #03D2D8 0.35%, #0094B2 100%)',
  [SOCIAL_PLATFORMS_NAMES.youtube.name]: 'linear-gradient(90deg, #FF2714 0.35%, #CB0F00 100%)',
  [SOCIAL_PLATFORMS_NAMES.twitch.name]: 'linear-gradient(28deg, rgba(135,76,246,1) 0%, rgba(206,180,255,1) 100%)',
}

export const borderColorFromPlatform = {
  [SOCIAL_PLATFORMS_NAMES.instagram.name]: '#FF5500',
  [SOCIAL_PLATFORMS_NAMES.tiktok.name]: '#00C8CE',
  [SOCIAL_PLATFORMS_NAMES.youtube.name]: '#FF2714',
  [SOCIAL_PLATFORMS_NAMES.twitch.name]: '#874CF6'
}

export const StyledCreatorsSuggestedInput = styled(TextField)({
  maxWidth: '300px',
  width: '100%',
  backgroundColor: '#FDFCFF',
  borderRadius: '24px',
  '.MuiInputBase-root': {
    color: '#442424',
    fontWeight: 600,
    fontSize: '13px',
    fontFamily: `Inter, sans-serif`,
    height: '40px',
  },
  '& .MuiOutlinedInput-root': {
    paddingLeft: '3px',
    paddingRight: '6px',
    borderRadius: '8px',
    outline: 'unset',
  },
  '& .MuiFormLabel-root': {
    left: '5px',
    top: '-1px',
  },
  '& input::placeholder': {
    fontSize: '13px',
    fontWeight: 500,
    fontFamily: `Inter, sans-serif`,
    color: '#E3C5C5',
  },
})

export const StyledCardCreatorList = styled(Card)({
  maxWidth: '326px',
  maxHeight: '286px',
  backgroundColor: '#FDFCFF',
  width: '100%',
  position: 'absolute',
  zIndex: 3,
  top: 6,
  padding: 0,
  '.MuiList-root': {
    paddingTop: 0,
  },
})

export const StyledListItem = styled(ListItem)({
  padding: '16px 4px',
  maxHeight: '50px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(255, 93, 90, 0.08)',
    borderRadius: '8px',
  },
})

export const StyledListItemText = styled(ListItemText)({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  color: '#212B36',
  '.MuiTypography-root': {
    fontSize: '14px',
  },
})

export const StyledSocialPlatformTabs = styled(Tabs)({
  minHeight: '28px',
  paddingRight: '1px',
  transition: 'transition: all 300ms ease',
  '& .MuiTabs-indicator': {
    height: '100%',
    zIndex: 1,
    borderRadius: '4px',
    backgroundColor: '#F4E8E8',
  },
})

export const StyledSocialPlatformTab = styled(Tab)(({ theme, current_tab, is_active, value }) => ({
  minHeight: '28px',
  minWidth: '28px',
  backgroundColor: 'transparent',
  background: is_active === 'true' ? bgGradients[current_tab] : '#F4E8E8',
  borderRadius: '4px',
  zIndex: 2,
  '&:hover': {
    borderRadius: '4px',
    background: bgGradients[value],
    '& svg': {
      fill: 'white !important',
      '& path': {
        fill: 'white !important',
      },
    },
  },
}))

export const StyledSkeleton = styled(Skeleton)({
  backgroundColor: '#EED9D9',
})

export const StyledAddedText = styled(Typography)({
  color: 'rgba(54, 179, 126, 1)',
  fontWeight: 500,
  textTransform: 'lowercase',
})
