import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Stack, Box, IconButton, Badge, Tooltip, ClickAwayListener, Fade } from '@mui/material'
import useTranslation from '../../../localization/useTranslation'
import { userCredsSelector, userGrantSelector } from '../../../redux/selectors'
import WithTip from '../../../components/common/popups/WithTip'
import AnalyzeInlinedNavbarContainer from '../../../components/analyzeBar/AnalyzeInlinedNavbarContainer'
import { NavbarLinkItem } from './NavbarLinkItem'
import {
  NavbarCampaignsIcon,
  NavbarCollectionsIcon,
  NavbarInfludataIcon,
  NavbarMessageIcon,
  NavbarSearchIcon,
} from './navbarIcons'
import NavBarUserRelated from './NavbarUserRelated'
import ReactivationPlanContainer from '../../../components/appUserSettings/pricingPlans/ReactivationPlanContainer'
import CompareProfilesDynamicNavItem from './CompareProfilesDynamicNavItem'
import { markNotificationsAsSeen } from '../../../redux/ducks/startupActions'
import { Notifications } from './Notifications'
import { cancelAllFetches } from '../search-sidebar/searchSidebar/searchbarTipsFetches'
import { changeSuggestionsData, resetSearchResults } from '../../../redux/ducks/searchDucks'
import { useHistory, useLocation } from 'react-router-dom'

const notificationsLabel = count => {
  if (count === 0) {
    return 'no notifications'
  }
  if (count > 99) {
    return 'more than 99 notifications'
  }
  return `${count} notifications`
}

export const NavbarLinks = ({
  isUserAuthorized,
  allowCollections,
  allowTracking,
  onLoginClick,
  currentPagePath,
}) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const {
    enable_collections,
    enable_ig_search,
    enable_tt_search,
    enable_yt_search,
    no_plan,
  } = useSelector(userGrantSelector, shallowEqual)
  const { notifications } = useSelector(userCredsSelector)
  const { notificationArray } = notifications || {}
  const BADGE_VALUE = notificationArray?.length
    ? notificationArray.filter(x => !x.seenInApp).length
    : 0

  const disabledSearch = !enable_ig_search && !enable_tt_search && !enable_yt_search
  const disabledCollection = !enable_collections

  const isHideAnalyzeComponent = pathname?.includes('search')

  const [isShowNotifications, setShowNotifications] = useState(false)

  const onOpenNotifications = event => {
    // @ts-ignore
    dispatch(markNotificationsAsSeen({ location: 'notifications_tab' }))
    setShowNotifications(true)
  }

  const onCloseNotifications = () => {
    setShowNotifications(false)
  }

  const clearSearch = toSearchPage => {
    cancelAllFetches()
    // @ts-ignore
    dispatch([resetSearchResults('', '', true), changeSuggestionsData({})])
    toSearchPage && history.push('/')
  }

  return (
    <Box id="navbar" sx={{ width: '100%' }}>
      <ReactivationPlanContainer />
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box sx={{ width: '100%', ml: '58px' }}>
          <Link to="/" onClick={() => clearSearch(false)}>
            <NavbarInfludataIcon />
          </Link>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={3}
          sx={{
            pointerEvents: !isUserAuthorized || no_plan ? 'none' : 'auto',
            opacity: !isUserAuthorized || no_plan ? 0.4 : 1,
          }}
        >
          <Box onClick={() => clearSearch(true)}>
            <NavbarLinkItem
              linkPath={'search'}
              Icon={NavbarSearchIcon}
              is_active={currentPagePath?.startsWith('/search')}
              is_disabled={disabledSearch || no_plan}
              hoverText={'Search'}
              disableRipple
            />
          </Box>

          {!enable_collections && isUserAuthorized ? (
            <WithTip tipText={labelStrings.collectionDisabledTip} position="bottom center">
              <Box onClick={() => clearSearch(false)}>
                <NavbarLinkItem
                  Icon={NavbarCollectionsIcon}
                  linkPath={'collections/'}
                  withSignup={!allowCollections && !isUserAuthorized}
                  signupTipText={labelStrings.useCollections}
                  is_active={currentPagePath?.startsWith('/collections')}
                  is_disabled={!isUserAuthorized || !enable_collections || no_plan}
                  hoverText={'Collections'}
                  disableRipple
                />
              </Box>
            </WithTip>
          ) : (
            <Box onClick={() => clearSearch(false)}>
              <NavbarLinkItem
                Icon={NavbarCollectionsIcon}
                linkPath={'collections/'}
                withSignup={!allowCollections && !isUserAuthorized}
                signupTipText={labelStrings.useCollections}
                is_active={currentPagePath?.startsWith('/collections')}
                is_disabled={!isUserAuthorized || disabledCollection || no_plan}
                hoverText={'Collections'}
                disableRipple
              />
            </Box>
          )}

          <Box onClick={() => clearSearch(false)}>
            <NavbarLinkItem
              Icon={NavbarCampaignsIcon}
              linkPath={'campaigns'}
              withSignup={!allowTracking && !isUserAuthorized}
              signupTipText={labelStrings.useTracking}
              is_active={currentPagePath?.startsWith('/campaigns')}
              is_disabled={!isUserAuthorized || no_plan}
              hoverText={'Campaigns'}
              disableRipple
            />
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" sx={{ width: '100%' }} justifyContent="end">
          {isUserAuthorized && (
            <Box
              sx={{
                mr: 2,
              }}
            >
              <ClickAwayListener onClickAway={onCloseNotifications}>
                <div>
                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 300 }}
                    open={isShowNotifications}
                    onClose={onCloseNotifications}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<Notifications onCloseNotifications={onCloseNotifications} />}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#FFF',
                          maxWidth: '450px',
                          p: 0,
                          m: 0,
                          mt: '8px !important',
                          boxShadow: `0px 18.105px 33.77px -11.02px rgba(107, 69, 69, 0.20);`,
                        },
                      },
                    }}
                  >
                    <IconButton
                      size="small"
                      disableRipple
                      aria-label={notificationsLabel(notificationArray?.length)}
                      onClick={isShowNotifications ? onCloseNotifications : onOpenNotifications}
                      sx={{
                        color: '#A06E6E',
                        '&:hover': {
                          color: '#FF5500',
                        },
                        '& .MuiBadge-badge': {
                          backgroundColor: '#ff2a00',
                          color: '#fff',
                          fontSize: '14px',
                          height: '16px',
                          minWidth: '16px',
                          paddingTop: '1px !important',
                          padding: '0 6px',
                          transform: 'translate(3px, -3px)', // Moves left by 4px and down by 4px
                        },
                      }}
                    >
                      {BADGE_VALUE > 0 ? ( // Conditionally render the Badge only if BADGE_VALUE > 0
                        <Badge badgeContent={BADGE_VALUE}>
                          <NavbarMessageIcon />
                        </Badge>
                      ) : (
                        <NavbarMessageIcon /> // Render only the icon if no badge is needed
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Box>
          )}

          {!isHideAnalyzeComponent && (
            <Box
              sx={{
                mr: 1,
                pointerEvents: !isUserAuthorized || no_plan ? 'none' : 'auto',
                opacity: !isUserAuthorized || no_plan ? 0.4 : 1,
              }}
            >
              <AnalyzeInlinedNavbarContainer />
            </Box>
          )}

          {/* <ToolsForBrandsWrapper>
                      <NavbarLinkItem
                        Icon={BuildIcon}
                        linkPath={'tools-for-brands'}
                        withSignup={!isUserAuthorized}
                        signupTipText={labelStrings.useToolsForBrands}
                        isActive={currentPagePath.includes('tools-for-brands')}
                      />
                    </ToolsForBrandsWrapper> */}

          <NavBarUserRelated onLoginClick={onLoginClick} isUserAuthorized={isUserAuthorized} />

          <CompareProfilesDynamicNavItem
            isUserAuthorized={isUserAuthorized}
            currentPagePath={currentPagePath}
          />
        </Stack>
      </Stack>
    </Box>
  )
}
