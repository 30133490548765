import React, { useRef, useState } from 'react'
import {
  TableRow,
  TableCell,
  Stack,
  Typography,
  Chip,
  Button,
  Avatar,
  Tooltip,
  Box,
} from '@mui/material'
import { Link } from 'react-router-dom'
import Iconify from '../../../components/iconify'
import {
  getPngCountryFlags,
  pretifyBigNum,
  findCountryIsoCode,
  constructLinkToWECFileServer,
  convertValueToPercentage,
} from '../../../../utils'
import { ProfileAvatar } from './ProfileAvatar'
import {
  AdditionalFieldDetailsOverlay,
  IconVerifiedWrapper,
  ModalContainer,
  ProfileImagesWrapper,
  ProfileImgWrapper,
} from '../../../../components/profileCommon/profileListTable/tableStyles'
import { VerifiedProfileIcon } from '../../../../components/common/icons/icons'
import useTranslation from '../../../../localization/useTranslation'
import { SearchUserGrowthDynamics } from './UserGrowthDynamics'
import RelativePortal from 'react-relative-portal'
import LazyImage from '../../../../components/lazyImage'
import ProfileTools from './ProfileTools/ProfileTools'
// import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { QueryMatchedContent } from './QueryMatchedContent'
import { simulateResize } from '../utils'
import { AddProfileToCollection } from './AddProfileToCollection'
import { searchContentTypes } from '../../../../constants/search'
import { USER_INDEXES } from '../../../../constants/appSettings'
import { setLastPageBeforeProfileRedirect } from '../../../../redux/ducks/appSettingsDucks'
import { useDispatch } from 'react-redux'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'

const stylesLabel = {
  color: `#333333`,
  fontFamily: `Inter`,
  fontSize: `14px`,
  fontStyle: `normal`,
  fontWeight: 650,
  lineHeight: `21.5px`,
}

const stylesLightText = {
  color: `#939393`,
  fontFamily: `Inter`,
  fontSize: `13px`,
  fontStyle: `normal`,
  fontWeight: 400,
  lineHeight: `18px`,
}

export const SearchTableRow = props => {
  const { countryOptions, languageOptions, labelStrings } = useTranslation() || {}
  const dispatch = useDispatch()
  const modalRef = useRef(null)

  const {
    row,
    // setIsOpenQuickPreview,
    // setQuickPreviewAnchorEl,
    // setCurrentDataForQuickPreview,
    // currentQuery,
    tableWidth,
    initialTableWidth,
    isLookaLikeList,
    collectionsList,
    expandCollectionsList,
    setExpandCollectionsList,
    setSearchUsers,
    usersData,
    countResults,
    setIsCreateCollectionWithCurrentProfile,
    handleClose,
    onBlockUnblockUser,
    searchType,
    currentPlatform,
  } = props || {}

  const {
    profileScore,
    isInfluDataVerified,
    monthlyGrowthFollowers,
    _index,
    _id,
    profilePicURL,
    displayName,
    username,
    followers,
    engagementMean,
    country,
    language,
    description,
    queryMatchedContent,
    categories,
    city,
    foundKeywordsArray,
    matchRate,
    mentions,
    audienceAnalysis,
  } = row || {}

  const categoryIcons = {
    fashion: 'mdi:hanger',
    fitness: 'mdi:dumbbell',
    beauty: 'mdi:lipstick',
    sports: 'mdi:soccer',
    food: 'mdi:food',
    diet: 'mdi:food-apple-outline',
    veganism: 'mdi:leaf',
    travel: 'mdi:airplane',
    books: 'mdi:book',
    interior: 'mdi:sofa',
    comedy: 'mdi:emoticon',
    tech: 'mdi:laptop',
    art: 'mdi:palette',
    education: 'mdi:school',
    family: 'mdi:home-heart',
    media: 'mdi:television',
    music: 'mdi:music',
    lgbtq: 'mdi:heart',
    gaming: 'mdi:controller',
    business: 'mdi:briefcase',
    automotive: 'mdi:car',
    sustainability: 'mdi:recycle',
    animals: 'mdi:dog',
    charity: 'mdi:hand-heart',
    politics: 'mdi:account-group',
  }

  const getCategoryIcon = category => {
    // Find the first matching key in categoryIcons
    const matchedIconKey = Object.keys(categoryIcons).find(key =>
      category.toLowerCase().includes(key)
    )
    return categoryIcons[matchedIconKey] || 'mdi:tag' // Default to 'mdi:tag' if no match
  }

  // Function to render categories with original icons
  const renderCategories = () => {
    return categories?.map((el, idx) => {
      const icon = getCategoryIcon(el.category)
      
      return (
        <Tooltip 
          key={idx}
          title={el.category}
          arrow
          placement="top"
        >
          <span
            style={{
              display: 'inline-flex',
              color: '#636363',
              marginRight: '10px',
              cursor: 'pointer',
            }}
          >
            <Iconify icon={icon} />
          </span>
        </Tooltip>
      )
    })
  }

  // const keywordsFromQuery = currentQuery.keywords
  //   ? currentQuery.keywords?.split(",")
  //   : []

  // const brandMentionsFromQuery = currentQuery.brandMentions
  // ? currentQuery.brandMentions?.split(",")
  // : []

  // const creatorCollaborationsFromQuery = currentQuery.creatorCollaborations
  // ? currentQuery.creatorCollaborations?.split(",")
  // : []

  // const keywordsFromQuery = currentQuery.keywords
  //   ? currentQuery.keywords?.split(",")
  //   : [];
  // const brandMentionsFromQuery = currentQuery.brandMentions
  //   ? currentQuery.brandMentions?.split(",")
  //   : [];
  // const creatorCollaborationsFromQuery = currentQuery.creatorCollaborations
  //   ? currentQuery.creatorCollaborations?.split(",")
  //   : [];
  //const combinedBubbleArray = [...keywordsFromQuery, ...brandMentionsFromQuery, ...creatorCollaborationsFromQuery]

  const isQueryMatchedContent = queryMatchedContent?.length > 0

  const link = `/profile/${_id}`

  const [isAddToCollectionOpened, setIsAddToCollectionOpened] = useState(false)
  const [isCreateCollectionOpened, setIsCreateCollectionOpened] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const [openQueryMatchedContent, setOpenQueryMatchedContent] = useState(false)

  const isExpandedCollectionsList = expandCollectionsList === _id

  // const handleClick = (event) => {
  //   event.preventDefault()
  //   event.stopPropagation()
  //   setQuickPreviewAnchorEl(event.currentTarget)
  //   setIsOpenQuickPreview((prev) => !prev)
  //   setCurrentDataForQuickPreview(row)
  // }

  const isProfileVerified = !!isInfluDataVerified

  const userCountryFlag = getPngCountryFlags(findCountryIsoCode(countryOptions, country))
    ? getPngCountryFlags(findCountryIsoCode(countryOptions, country))
    : country
  const userLanguage = languageOptions?.find(el => el.value === language) || {}

  const onOpenMatchedContent = e => {
    e.preventDefault()
    e.stopPropagation()
    setOpenQueryMatchedContent(prev => !prev)
    simulateResize()
  }

  // Helper function to get the top audience age group
  const getTopAgeGroup = ageGroups => {
    if (!ageGroups || !ageGroups.length) return ''

    // Find age group with highest value
    const topAgeGroup = [...ageGroups].sort((a, b) => b.value - a.value)[0]
    
    // Return the age (current year - birthyear), not the birthyear itself
    return topAgeGroup.birthyear ? new Date().getFullYear() - topAgeGroup.birthyear : ''
  }

  const setSource = () => {
    dispatch(setLastPageBeforeProfileRedirect('search'))
  }

  const isShowWorkedWithSection =
    !mentions?.length ||
    searchType !== searchContentTypes.CREATOR ||
    _index !== USER_INDEXES.instagram
  const WorkedWithSection = isShowWorkedWithSection ? null : (
    <Stack spacing={0.25} alignItems="flex-start" sx={{ maxWidth: 'fit-content' }}>
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '10px',
          color: '#939393',
          fontWeight: 400,
        }}
      >
        {labelStrings?.workedWith}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={0.5} sx={{ pb: 0.5 }}>
        {mentions?.map((item, idx) => {
          if (idx > 4) return
          return (
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#FFF1F1',
                    boxShadow: `0px 23px 42.9px -14px rgba(107, 69, 69, 0.20)`,
                    p: 0,
                  },
                },
              }}
              title={
                <Stack spacing={0.5} sx={{ p: 1 }}>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      color: '#6B4545',
                      fontWeight: 500,
                      lineHeight: 'initial',
                    }}
                  >
                    @{item?.mention}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      color: '#6B4545',
                      fontWeight: 400,
                      lineHeight: 'initial',
                    }}
                  >
                    {item?.displayName}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      color: '#6B4545',
                      fontWeight: 400,
                      lineHeight: 'initial',
                    }}
                  >
                    {labelStrings?.mentions}:{' '}
                    {Math.round(item?.storycount + item?.postcount + item?.reelcount) || 0}
                  </Typography>
                </Stack>
              }
            >
              <Avatar
                sx={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.7,
                  },
                }}
                sizes="24"
                alt={item.displayName}
                key={item.instagramId}
                src={item.imageUrl}
              />
            </Tooltip>
          )
        })}
      </Stack>
    </Stack>
  )

  return (
    <>
      <TableRow
        onClick={setSource}
        className="main-search-row"
        component={Link}
        to={link}
        hover
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          backgroundColor: '#FBFBFB',
          '&:hover': {
            textDecoration: 'none',
            backgroundColor: '#F5F5F5',
          },
        }}
      >
        <TableCell
          align="left"
          sx={{
            cursor: 'pointer',
            pb: '15px',
          }}
        >
          <ModalContainer
            ref={modalRef}
            onMouseOver={() => setOpenModal(true)}
            onMouseLeave={() => setOpenModal(false)}
          >
            <Stack sx={{ position: 'relative' }}>
              <ProfileAvatar
                score={profileScore?.overall}
                profilePicUrl={profilePicURL}
                userindex={_index}
                withRating={true}
                withOverlay={openModal}
                size={75}
              />
              {isProfileVerified && (
                <IconVerifiedWrapper>
                  <VerifiedProfileIcon width={25} height={28} />
                </IconVerifiedWrapper>
              )}
            </Stack>
            {openModal && !!row?.postLinksArray?.length && (
              <RelativePortal component="div">
                <AdditionalFieldDetailsOverlay>
                  <ProfileImagesWrapper>
                    {row?.postLinksArray?.map((el, index) => (
                      <ProfileImgWrapper key={index}>
                        <LazyImage key={index} src={constructLinkToWECFileServer(el)} alt="" />
                      </ProfileImgWrapper>
                    ))}
                  </ProfileImagesWrapper>
                </AdditionalFieldDetailsOverlay>
              </RelativePortal>
            )}
          </ModalContainer>
        </TableCell>

        <TableCell align="left" sx={{ pb: '15px' }}>
          <Stack sx={{ minWidth: '200px' }}>
            <Typography sx={{ ...stylesLabel }}>
              {displayName && displayName.length > 25
                ? displayName.slice(0, 23) + '...'
                : displayName}
            </Typography>
            <Typography sx={{ ...stylesLightText }}>@{username}</Typography>
            <Stack
              spacing={0.5}
              sx={{ mt: 1, maxWidth: '400px' }}
            >
              <Stack direction="row" alignItems="center">
                {userCountryFlag && (
                  <Typography
                    sx={{ color: '#939393', fontSize: '11px', mr: 1.5, display: 'flex', alignItems: 'center' }}
                  >
                    <Box component="span" sx={{ mr: 0.5 }}>{city ? city : ''}</Box>
                    {userCountryFlag}
                  </Typography>
                )}
                <Typography
                  sx={{
                    color: '#939393',
                    fontSize: '11px',
                    fontFamily: 'Inter',
                  }}
                >
                  {userLanguage.label ? userLanguage.label : ''}
                </Typography>
              </Stack>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                {renderCategories()}
              </div>
            </Stack>
          </Stack>
        </TableCell>

        <TableCell align="left" sx={{ pb: '15px' }}>
          <Stack sx={{ maxWidth: '100px' }} alignItems="center">
            <Typography sx={{ ...stylesLabel, color: '#636363' }}>{pretifyBigNum(followers)}</Typography>
            <Typography sx={{ position: 'relative', width: '100%' }}>
              {!!monthlyGrowthFollowers?.length && (
                <SearchUserGrowthDynamics growthData={monthlyGrowthFollowers} />
              )}
            </Typography>
          </Stack>
        </TableCell>

        {currentPlatform !== SOCIAL_PLATFORMS_NAMES.twitch.name && (
          <TableCell align="center" sx={{ ...stylesLabel, pb: '15px', color: '#636363' }}>
            {engagementMean}
          </TableCell>
        )}

        <TableCell align="left" sx={{ pb: '15px' }}>
          {
            <Typography
              dangerouslySetInnerHTML={{ __html: description }}
              sx={{
                color: '#636363',
                fontFamily: 'Inter',
                maxWidth: '490px',
                minWidth: '150px',
                fontWeight: 400,
                fontSize: '13px',
                lineHeight: '18px',
              }}
            />
          }
        </TableCell>

        {isLookaLikeList && (
          <TableCell align="center" sx={{ ...stylesLabel, pb: '15px', color: '#636363' }}>
            {convertValueToPercentage(matchRate)}
          </TableCell>
        )}

        <TableCell align="right" sx={{ pb: '15px' }}>
          <ProfileTools
            user={{ ...row }}
            isSearchView={true}
            stopEventBubbling={() => {}}
            isSelectedProfileView={false}
            /*for add to collections */
            isAddToCollectionOpened={isAddToCollectionOpened}
            isCreateCollectionOpened={isCreateCollectionOpened}
            setIsAddToCollectionOpened={setIsAddToCollectionOpened}
            setIsCreateCollectionOpened={setIsCreateCollectionOpened}
            // for black list
            handleClose={handleClose}
            onBlockUnblockUser={onBlockUnblockUser}
            source="search"
          />
        </TableCell>

        {/*<TableCell
          align='center'
          sx={{ pb: isQueryMatchedContent ? "50px" : "16px" }}
        >
          <IconButton
            sx={{ color: "#784E4E", "&:hover": { color: "#FF5500" } }}
            onClick={handleClick}
          >
            <Iconify icon='jam:search-plus' width='24px' height='24px' />
          </IconButton>
        </TableCell>*/}
      </TableRow>

      <TableRow
        sx={{
          width: '100%',
          position: 'relative',
          height:
            isQueryMatchedContent
              ? '120px' // Added 200px to the original height
              : !isShowWorkedWithSection || audienceAnalysis
              ? '60px'
              : '40px',
          borderBottom: !isQueryMatchedContent && '1px solid #F5F5F5',
          backgroundColor: '#FBFBFB',
          '&:last-child': {
            borderBottom: 'none',
          },
        }}
      >
        <TableCell sx={{ pt: '5px', width: '1px' }}></TableCell>
        <Stack
          sx={{
            position: 'absolute',
            px: 4,
            py: 0.5,
            left: 0,
            width: '100%',
          }}
          direction="row"
          alignItems={!isQueryMatchedContent ? 'center' : 'end'}
          justifyContent="space-between"
        >
          {isQueryMatchedContent ? (
            <Stack sx={{ width: '85%' }}>
              <Stack direction="row" spacing={6} alignItems="center">
                {WorkedWithSection}

                {/* Audience Metrics Section */}
                {audienceAnalysis && (
                  <Stack
                    spacing={0.5}
                    sx={{
                      mt: WorkedWithSection ? 2 : 0,
                      mb: !WorkedWithSection ? 2 : 0,
                      minWidth: '350px',
                      height: '54px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '10px',
                        color: '#939393',
                        fontWeight: 400,
                        textAlign: 'left',
                      }}
                    >
                      {labelStrings?.topAudienceReport || 'Top Audience Demographics'}
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={1.5}
                      sx={{ pb: WorkedWithSection ? 1 : 2, height: '32px' }}
                    >
                      {/* Country */}
                      {audienceAnalysis?.countryArray?.[0] && (
                        <Tooltip
                          title={audienceAnalysis.countryArray[0].country || ''}
                          arrow
                          placement="top"
                        >
                          <Stack direction="row" alignItems="center" spacing={0.5}>
                            {getPngCountryFlags(
                              findCountryIsoCode(
                                countryOptions,
                                audienceAnalysis.countryArray[0].country
                              )
                            )}
                            <Typography sx={{ fontSize: '12px', color: '#565656' }}>
                              {audienceAnalysis.countryArray[0]?.value != null
                                ? audienceAnalysis.countryArray[0].value.toFixed(0)
                                : 0}
                              %
                            </Typography>
                          </Stack>
                        </Tooltip>
                      )}

                      {/* Language */}
                      {audienceAnalysis?.languageArray?.[0] && (
                        <Tooltip
                          title={
                            languageOptions?.find(
                              l => l.value === audienceAnalysis.languageArray[0].language
                            )?.label || audienceAnalysis.languageArray[0].language
                          }
                          arrow
                          placement="top"
                        >
                          <Stack direction="row" alignItems="center" spacing={0.5}>
                            <Iconify icon="material-symbols:translate" />
                            <Typography sx={{ fontSize: '12px', color: '#565656' }}>
                              {audienceAnalysis.languageArray[0]?.value != null
                                ? audienceAnalysis.languageArray[0].value.toFixed(0)
                                : 0}
                              % {audienceAnalysis.languageArray[0]?.language || ''}
                            </Typography>
                          </Stack>
                        </Tooltip>
                      )}

                      {/* Gender */}
                      {audienceAnalysis?.genderMtoF && (
                        <Tooltip
                          title={`Gender distribution: ${
                            audienceAnalysis.genderMtoF?.male != null
                              ? audienceAnalysis.genderMtoF.male.toFixed(0)
                              : 0
                          }% Male, ${
                            audienceAnalysis.genderMtoF?.female != null
                              ? audienceAnalysis.genderMtoF.female.toFixed(0)
                              : 0
                          }% Female`}
                          arrow
                          placement="top"
                        >
                          <Stack direction="row" alignItems="center" spacing={0.5}>
                            <Iconify icon="mdi:account-group" />
                            <Typography sx={{ fontSize: '12px', color: '#565656' }}>
                              {(audienceAnalysis.genderMtoF?.male || 0) >
                              (audienceAnalysis.genderMtoF?.female || 0)
                                ? `${
                                    audienceAnalysis.genderMtoF?.male != null
                                      ? audienceAnalysis.genderMtoF.male.toFixed(0)
                                      : 0
                                  }% Male`
                                : `${
                                    audienceAnalysis.genderMtoF?.female != null
                                      ? audienceAnalysis.genderMtoF.female.toFixed(0)
                                      : 0
                                  }% Female`}
                            </Typography>
                          </Stack>
                        </Tooltip>
                      )}

                      {/* Age */}
                      {(audienceAnalysis?.birthyearMedian ||
                        audienceAnalysis?.averageBirthyearArray) && (
                        <Tooltip
                          title={
                            audienceAnalysis?.birthyearMedian
                              ? `Average age: ${audienceAnalysis?.birthyearMedian} Years`
                              : ''
                          }
                          arrow
                          placement="top"
                        >
                          <Stack direction="row" alignItems="center" spacing={0.5}>
                            <Iconify icon="mdi:account-child" />
                            <Typography sx={{ fontSize: '12px', color: '#565656' }}>
                              {audienceAnalysis?.birthyearMedian
                                ? `Ø ${audienceAnalysis?.birthyearMedian} Yrs.`
                                : ''}
                            </Typography>
                          </Stack>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%' }}>
                {/* <Tooltip title={labelStrings.queryMatchedTooltipText}>
              <InfoRoundedIcon sx={{ fill: '#F6DBDB', fontSize: '16px' }} />
            </Tooltip> */}
                <Typography sx={{ fontSize: '13px', fontFamily: 'Inter', color: '#565656' }}>
                  {labelStrings.found} {/*labelStrings.contentPiecesContaining*/}
                </Typography>
                {foundKeywordsArray?.map((el, idx) => {
                  if (idx > 2) return
                  return (
                    <Chip
                      key={`${idx}-${el.term}-${el.count}`}
                      size="small"
                      sx={{
                        color: '#565656',
                        backgroundColor: '#F4F6F8',
                        fontFamily: 'Inter',
                        border: '1px solid #E0E0E0'
                      }}
                      label={`${el.count}x ${el.term}`}
                    />
                  )
                })}

                <Button
                  size="small"
                  sx={{
                    px: 1,
                    '& .MuiButton-endIcon': { ml: '2px' },
                    '&:hover': { borderRadius: '16px' },
                  }}
                  variant="text"
                  onClick={onOpenMatchedContent}
                  endIcon={
                    openQueryMatchedContent ? (
                      <KeyboardArrowUpRoundedIcon />
                    ) : (
                      <KeyboardArrowDownRoundedIcon />
                    )
                  }
                >
                  {openQueryMatchedContent ? labelStrings.hide : labelStrings.show}
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Stack sx={{ width: '85%' }} direction="row" spacing={6} alignItems="flex-start">
              {WorkedWithSection}

              {/* Audience Metrics Section */}
              {audienceAnalysis && (
                <Stack
                  spacing={0.5}
                  sx={{
                    mt: WorkedWithSection ? 2 : 0,
                    mb: !WorkedWithSection ? 2 : 0,
                    minWidth: '350px',
                    height: '54px',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '10px',
                      color: '#939393',
                      fontWeight: 400,
                      textAlign: 'left',
                    }}
                  >
                    {labelStrings?.topAudienceReport || 'Top Audience Demographics'}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1.5}
                    sx={{ pb: WorkedWithSection ? 1 : 2, height: '32px' }}
                  >
                    {/* Country */}
                    {audienceAnalysis?.countryArray?.[0] && (
                      <Tooltip
                        title={audienceAnalysis.countryArray[0].country || ''}
                        arrow
                        placement="top"
                      >
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          {getPngCountryFlags(
                            findCountryIsoCode(
                              countryOptions,
                              audienceAnalysis.countryArray[0].country
                            )
                          )}
                          <Typography sx={{ fontSize: '12px', color: '#565656' }}>
                            {audienceAnalysis.countryArray[0]?.value != null
                              ? audienceAnalysis.countryArray[0].value.toFixed(0)
                              : 0}
                            %
                          </Typography>
                        </Stack>
                      </Tooltip>
                    )}

                    {/* Language */}
                    {audienceAnalysis?.languageArray?.[0] && (
                      <Tooltip
                        title={
                          languageOptions?.find(
                            l => l.value === audienceAnalysis.languageArray[0].language
                          )?.label || audienceAnalysis.languageArray[0].language
                        }
                        arrow
                        placement="top"
                      >
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <Iconify icon="material-symbols:translate" />
                          <Typography sx={{ fontSize: '12px', color: '#565656' }}>
                            {audienceAnalysis.languageArray[0]?.value != null
                              ? audienceAnalysis.languageArray[0].value.toFixed(0)
                              : 0}
                            % {audienceAnalysis.languageArray[0]?.language || ''}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    )}

                    {/* Gender */}
                    {audienceAnalysis?.genderMtoF && (
                      <Tooltip
                        title={`Gender distribution: ${
                          audienceAnalysis.genderMtoF?.male != null
                            ? audienceAnalysis.genderMtoF.male.toFixed(0)
                            : 0
                        }% Male, ${
                          audienceAnalysis.genderMtoF?.female != null
                            ? audienceAnalysis.genderMtoF.female.toFixed(0)
                            : 0
                        }% Female`}
                        arrow
                        placement="top"
                      >
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <Iconify icon="mdi:account-group" />
                          <Typography sx={{ fontSize: '12px', color: '#565656' }}>
                            {(audienceAnalysis.genderMtoF?.male || 0) >
                            (audienceAnalysis.genderMtoF?.female || 0)
                              ? `${
                                  audienceAnalysis.genderMtoF?.male != null
                                    ? audienceAnalysis.genderMtoF.male.toFixed(0)
                                    : 0
                                }% Male`
                              : `${
                                  audienceAnalysis.genderMtoF?.female != null
                                    ? audienceAnalysis.genderMtoF.female.toFixed(0)
                                    : 0
                                }% Female`}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    )}

                    {/* Age */}
                    {(audienceAnalysis?.birthyearMedian ||
                      audienceAnalysis?.averageBirthyearArray) && (
                      <Tooltip 
                        title={audienceAnalysis?.birthyearMedian ? `Average age: ${audienceAnalysis?.birthyearMedian} Years` : ''}
                        arrow 
                        placement="top"
                      >
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <Iconify icon="mdi:account-clock" />
                          <Typography sx={{ fontSize: '12px', color: '#565656' }}>
                            {audienceAnalysis?.birthyearMedian
                              ? `Ø ${audienceAnalysis?.birthyearMedian} Yrs.`
                              : audienceAnalysis?.averageBirthyearArray ? `Top age: ${getTopAgeGroup(audienceAnalysis.averageBirthyearArray)}` : ''}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}
          <AddProfileToCollection
            row={row}
            collectionsList={collectionsList}
            isExpandedCollectionsList={isExpandedCollectionsList}
            setExpandCollectionsList={setExpandCollectionsList}
            setSearchUsers={setSearchUsers}
            usersData={usersData}
            countResults={countResults}
            setIsCreateCollectionWithCurrentProfile={setIsCreateCollectionWithCurrentProfile}
            source="search"
          />
        </Stack>
      </TableRow>

      <QueryMatchedContent
        openQueryMatchedContent={openQueryMatchedContent}
        queryMatchedContent={queryMatchedContent}
        tableWidth={tableWidth}
        initialTableWidth={initialTableWidth}
      />
    </>
  )
}
