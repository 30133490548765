import { Button, FormControlLabel, List, ListItem, Typography, styled } from '@mui/material'

export const StyledBtnClickHere = styled(Button)({
  width: 'fit-content',
  textDecoration: 'underline',
  fontWeight: 600,
  fontFamily: 'Inter',
  '&:hover': {
    backgroundColor: 'transparent',
    filter: 'brightness(70%)',
    textDecoration: 'underline',
  },
})
export const TextWrapper = styled(Typography)({
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '22px',
  fontFamily: 'Inter',
})

export const StyledModalTitle = styled(Typography)({
  color: '#393939',
  lineHeight: '30px',
  fontSize: '30px',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: 'Inter',
})

export const StyledUserName = styled(Typography)({
  lineHeight: '20px',
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
  marginTop: '20px',
  fontFamily: 'Inter',
})

export const StyledTip = styled(Typography)({
  marginBottom: '15px',
  marginTop: '30px',
  width: '100%',
  fontFamily: 'Inter',
})

export const StyledOperationStatusTip = styled(Typography)({
  textAlign: 'center',
  padding: '30px 0',
  fontFamily: 'Inter',
})

export const StyledListItemsLabel = styled(Typography)({
  marginBottom: '0',
  marginTop: '0',
  width: '100%',
  fontFamily: 'Inter',
})

export const StyledList = styled(List)({
  paddingTop: 0,
  paddingLeft: '10px',
})

export const StyledListItem = styled(ListItem)({
  padding: '0',
  fontFamily: 'Inter',
  '&::before': {
    content: '"-"',
    marginRight: '10px',
  },
})

export const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: '16px',
    fontFamily: 'Inter',
  },
})

export const stylesModalContent = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '599px',
}
