import styled from 'styled-components'
import { WINDOW_WIDTH } from '../../constants/windowSizes'
import { USER_INDEXES } from '../../constants/appSettings'

export const MaxWidthContainer = styled.div`
  max-width: 1370px;
  margin: 0 auto;
  padding: 40px 20px;
`

export const Section = styled.div`
  padding: 40px;
  background-color: ${props => props.theme.colors.offWhite};
  border-radius: 25px;

  ${props => {
    if (props.isLeft) {
      return `
        width: 740px;
        padding: 40px;
        margin-right: 20px;

        @media screen and (max-width: 1240px) {
          width: 690px;
          padding: 20px;
        }
      `
    } else if (props.isRight) {
      return `
        width: 570px;
        padding: 40px 20px;

        @media screen and (max-width: 1240px) {
          padding: 20px;
        }
      `
    } else {
      return `
        width: 100%;
        margin-top: 25px;
      `
    }
  }}

  @media screen and (max-width: ${WINDOW_WIDTH.desktop}) {
    width: ${props => (props.isLeft ? 720 : 530)}px;
  }
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  ${props => props.customStyles || ''}
`

export const SectionTitleName = styled.div`
  font-size: 15px;
  font-weight: 800;
  line-height: 19px;
  text-transform: uppercase;
  ${props =>
    !props.isForPdf
      ? props.theme.getTextGradientStyles({ platform: props.platform })
      : `color: ${props.theme.getColorPaletteForPlatform(props.platform).main}`}
  margin-right: 5px;
`

export const SectionTitleBorder = styled.div`
  flex: 1;
  width: 100%;
  height: 1px;
  margin-left: 5px;
  background-color: ${props => props.theme.colors.mediumBrown};
  margin-top: ${props => props.isForPdf ? '20px' : 0};
`
export const YTButton = styled.button`
  ${props =>
    !props.isActive
      ? `
    border: 1px solid ${props.theme.colors.mauve};
    color: ${props.theme.colors.mauve};
    background: none;
  `
      : `
    border: solid 1px transparent;
    background-image:
    linear-gradient(rgba(255, 255, 255, 0),rgba(255, 255, 255, 0)),
      ${props.theme.gradients.mainYoutube};
    background-origin: border-box;
    background-clip: content-box, border-box;

    &:hover {
      box-shadow: none;
    }
  `}
  border-radius: 7px;
  color: ${props => (props.isActive ? props.theme.colors.white : props.theme.colors.mediumBrown)};
  font-weight: 700;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 6px 10px;
  ${'' /* margin-right: 13px;
  &:last-child {
    margin-right: 0;
  } */}
`

export const TWButton = styled.button`
  ${props =>
    !props.isActive
      ? `
    border: 1px solid ${props.theme.colors.purple};
    color: ${props.theme.colors.purple};
    background: none;
  `
      : `
    border: solid 1px transparent;
    background-image:
    linear-gradient(rgba(255, 255, 255, 0),rgba(255, 255, 255, 0)),
      ${props.theme.gradients.mainTwitch};
    background-origin: border-box;
    background-clip: content-box, border-box;

    &:hover {
      box-shadow: none;
    }
  `}
  border-radius: 7px;
  color: ${props => (props.isActive ? props.theme.colors.white : props.theme.colors.purple)};
  font-weight: 700;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 6px 10px;
  ${'' /* margin-right: 13px;
  &:last-child {
    margin-right: 0;
  } */}
`

export const IgTtButton = styled.button`
  ${props =>
    !props.isActive
      ? `
    border: 1px solid ${props.theme.colors.mauve};
    color: ${props.theme.colors.mauve};
    background: none;
  `
      : `
    border: solid 1px transparent;
    background-image:
    linear-gradient(rgba(255, 255, 255, 0),rgba(255, 255, 255, 0)),
      ${
        props.platform === USER_INDEXES.instagram
          ? props.theme.gradients.main
          : props.theme.gradients.mainTiktok
      };
    background-origin: border-box;
    background-clip: content-box, border-box;

    &:hover {
      box-shadow: none;
    }
  `}
  ${props => props.disabled && props.theme.disabled};
  border-radius: 7px;
  color: ${props => (props.isActive ? props.theme.colors.white : props.theme.colors.mediumBrown)};
  font-weight: 700;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 6px 10px;
  ${'' /* margin-right: 13px;
  &:last-child {
    margin-right: 0;
  } */}
  &:hover {
    opacity: 0.8;
  }
`
