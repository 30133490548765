import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Divider as MuiDivider,
  Box,
  ListItemButton,
  Popover,
  IconButton,
  Tooltip,
} from '@mui/material'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded'
import useTranslation from '../../../../../localization/useTranslation'
import { CountryFilter } from '../filters/CountryFilter'
import { CityFilter } from '../filters/CityFilter'
import { LanguageFilter } from '../filters/LanguageFilter'
import { CategoriesFilter } from '../filters/CategoriesFilter'
import { KeywordsFilter } from '../filters/KeywordsFilter'
import { AmountFilter } from '../filters/AmountFilter'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  filterTruthyKeys,
  getActiveSearchParams,
  getValuesFromQuery,
  hasMatchingValues,
} from '../utils'
import { EngRateFilter } from '../filters/EngRateFilter'
import { GrowthRateFilter } from '../filters/GrowthRateFilter'
import { BrandMentionFilter } from '../filters/BrandMentionFilter'
import { searchContentTypes } from '../../../../../constants/search'
import { ReachFilter } from '../filters/ReachFilter'
import { LocationFilter } from '../filters/LocationFilter'
import { LocationRadiusFilter } from '../filters/LocationRadius'
import { GenderFilter } from '../filters/GenderFilter'
import { AgeGroupRangeFilter } from '../filters/AgeGroupRangeFilter'

import { SOCIAL_PLATFORMS_NAMES, USER_INDEXES } from '../../../../../constants/appSettings'
import {
  CategoriesSvgIcon,
  CitySvgIcon,
  EngRateSvgIcon,
  FollowersSvgIcon,
  GenderSvgIcon,
  KeywordsSvgIcon,
  LangSvgIcon,
  // LocationRadiusSvgIcon,
  LocationSvgIcon,
  MentionsSvgIcon,
  ReachSvgIcon,
  AgeSvgIcon,
} from '../../icons'
import { animationHideSearchFilter, animationShowSearchFilter } from '../../../../theme/animations'
import { infludataThemes } from '../../../../../themes/infludataThemes'

export const CreatorSearchParams = props => {
  const {
    query,
    permissions,
    selectedPlatform,
    handlersMap,
    availableCountriesCities,
    suggestionsKeywords,
    searchType,
    currentOpenSearchParam,
    setCurrentOpenSearchParam,
    userIndex,
  } = props || {}
  const { labelStrings } = useTranslation()

  const activeQueryParams = filterTruthyKeys(query)
  const selectedCountries = query?.country?.split(',')
  const isDisabledCitySelect = selectedCountries?.length > 1

  const finedActiveParam = searchedParam => {
    const getActiveAmountParams = getActiveSearchParams(getValuesFromQuery, query)
    const keysName = Object.keys(getActiveAmountParams)

    const isReach =
      searchedParam === 'reach' &&
      (activeQueryParams?.includes('viewsMin') || activeQueryParams?.includes('viewsMax'))

    const isKeywords =
      (searchedParam === 'postCaptions' || searchedParam === 'keywords') &&
      (activeQueryParams?.includes('postCaptions') || activeQueryParams?.includes('keywords'))

    if (searchedParam === 'followers') {
      if (keysName?.length && query[keysName[0]] === 2000) return false
    }
    return activeQueryParams?.includes(searchedParam) || isReach || isKeywords
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [currId, setCurrId] = useState(null)

  const handlePopoverOpen = (event, id) => {
    setAnchorEl(event.currentTarget)
    setCurrId(id)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setCurrId(null)
    setCurrentOpenSearchParam('')
  }

  const menuForCreator = [
    {
      name: labelStrings.country,
      icon: <PublicRoundedIcon />,
      id: 'country',
      isDisabled: false,
      tipText: '',
    },
    {
      ...(selectedPlatform !== SOCIAL_PLATFORMS_NAMES.twitch.name && {
        name: labelStrings.city,
        icon: <CitySvgIcon />,
        id: 'city',
        isDisabled:
          !permissions.allowCitiesSearch || (!query.country && !query.city) || isDisabledCitySelect,
        tipText: isDisabledCitySelect
          ? labelStrings?.searchSuggestions?.pleaseUseSingleCountryToUse
          : labelStrings.searchSuggestions.disabledCity,
      }),
    },
    {
      name: userIndex === USER_INDEXES.youtube ? labelStrings.subscribers : labelStrings.followers,
      icon: <FollowersSvgIcon />,
      id: 'followers',
      isDisabled: false,
      tipText: '',
    },
    {
      ...(searchType !== searchContentTypes.BRAND && {
        name: labelStrings.language,
        icon: <LangSvgIcon />,
        id: 'language',
        isDisabled: false,
        tipText: '',
      }),
    },
    {
      ...(searchType !== searchContentTypes.BRAND && {
        name: labelStrings.gender,
        icon: <GenderSvgIcon />,
        id: 'gender',
        isDisabled: false,
        tipText: '',
      }),
    },
    /*{
      ...(selectedPlatform !== SOCIAL_PLATFORMS_NAMES.youtube.name &&
        selectedPlatform !== SOCIAL_PLATFORMS_NAMES.twitch.name &&
        searchType !== searchContentTypes.BRAND && {
          name: labelStrings.ageGroup,
          icon: <AgeSvgIcon />,
          id: 'ageGroup',
          isDisabled: false,
          tipText: '',
        }),
    },*/
    {
      name:
        selectedPlatform !== SOCIAL_PLATFORMS_NAMES.twitch.name
          ? labelStrings.menuKeywordslabel
          : labelStrings.description,
      icon: <KeywordsSvgIcon />,
      id: 'keywords',
      isDisabled: !permissions.allowKeywordsSearch,
      tipText: '',
    },

    {
      ...(selectedPlatform === SOCIAL_PLATFORMS_NAMES.twitch.name
        ? null
        : searchType === searchContentTypes.BRAND
        ? {
            name: labelStrings.creatorCollaborations,
            icon: <MentionsSvgIcon />,
            id: 'creatorCollaborations',
            isDisabled: false,
            tipText: labelStrings.searchSuggestions.creatorCollaborationsTips,
          }
        : {
            name: labelStrings.brandMentions,
            icon: <MentionsSvgIcon />,
            id: 'brandMentions',
            isDisabled: false,
            tipText: '',
          }),
    },
    {
      ...(selectedPlatform !== SOCIAL_PLATFORMS_NAMES.twitch.name && {
        name: labelStrings.categories,
        icon: <CategoriesSvgIcon />,
        id: 'categories',
        isDisabled: !permissions.allowKeywordsSearch,
        tipText: '',
      }),
    },
    {
      ...(selectedPlatform !== SOCIAL_PLATFORMS_NAMES.youtube.name &&
        selectedPlatform !== SOCIAL_PLATFORMS_NAMES.twitch.name && {
          name: 'Eng.Rate',
          icon: <EngRateSvgIcon />,
          id: 'engagementRate',
          isDisabled: false,
          tipText: '',
        }),
    },
    {
      name: labelStrings.growthRate,
      icon: <EngRateSvgIcon />,
      id: 'growthRate',
      isDisabled: false,
      tipText: '',
    },
  ]

  const menuForContent = [
    {
      name: labelStrings.menuKeywordslabel,
      icon: <KeywordsSvgIcon />,
      id: 'keywords',
      isDisabled: !permissions.allowKeywordsSearch,
      tipText: '',
    },
    {
      name: labelStrings.mentions,
      icon: <MentionsSvgIcon />,
      id: 'brandMentions',
      isDisabled: false,
      tipText: '',
    },
    {
      name: labelStrings.reach,
      icon: <ReachSvgIcon />,
      id: 'reach',
      isDisabled: false,
      tipText: '',
    },
    {
      name: labelStrings.language,
      icon: <LangSvgIcon />,
      id: 'language',
      isDisabled: false,
      tipText: '',
    },
    {
      name: labelStrings.location,
      icon: <LocationSvgIcon />,
      id: 'placesId',
      isDisabled: false,
      tipText: '',
    },
    /*{
      name: labelStrings.locationRadius,
      icon: <LocationRadiusSvgIcon s />,
      id: "radiusKM",
      disabled: !query?.placesId,
      tipText: "",
    },*/
  ]

  const menuLinks = searchType !== searchContentTypes.CONTENT ? menuForCreator : menuForContent
  const creatorFilters = {
    ['country']: (
      <CountryFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        isModalOpen={!!anchorEl}
      />
    ),
    ['city']: (
      <CityFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        availableCountriesCities={availableCountriesCities}
        isModalOpen={!!anchorEl}
      />
    ),
    ['language']: (
      <LanguageFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        isModalOpen={!!anchorEl}
        closeModal={handlePopoverClose}
      />
    ),
    ['categories']: (
      <CategoriesFilter
        query={query}
        handlersMap={handlersMap}
        isModalOpen={!!anchorEl}
        closeModal={handlePopoverClose}
      />
    ),
    ['keywords']: (
      <KeywordsFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        suggestionsKeywords={suggestionsKeywords}
      />
    ),
    ['followers']: (
      <AmountFilter
        query={query}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        searchType={searchType}
        isModalOpen={!!anchorEl}
      />
    ),
    ['engagementRate']: (
      <EngRateFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
    ['growthRate']: (
      <GrowthRateFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
    ['brandMentions']: (
      <BrandMentionFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
    ['creatorCollaborations']: (
      <BrandMentionFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        isBrandType
      />
    ),
    ['gender']: (
      <GenderFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        isModalOpen={!!anchorEl}
        closeModal={handlePopoverClose}
      />
    ),
    ['ageGroup']: (
      <AgeGroupRangeFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        isModalOpen={!!anchorEl}
        closeModal={handlePopoverClose}
      />
    ),
  }

  const contentFilters = {
    ['keywords']: (
      <KeywordsFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        suggestionsKeywords={suggestionsKeywords}
      />
    ),
    ['brandMentions']: (
      <BrandMentionFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        isContentType
      />
    ),
    ['reach']: (
      <ReachFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
    ['language']: (
      <LanguageFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
        isModalOpen={!!anchorEl}
        closeModal={handlePopoverClose}
      />
    ),
    ['placesId']: (
      <LocationFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
    ['radiusKM']: (
      <LocationRadiusFilter
        query={query}
        permissions={permissions}
        handlersMap={handlersMap}
        selectedPlatform={selectedPlatform}
      />
    ),
  }

  const allSearchParamsIds = menuLinks?.map(el => el.id)
  const idsForAmountSelect =
    searchType === searchContentTypes.CONTENT
      ? ['viewsMin', 'viewsMax']
      : [
          'followerMin',
          'followerMax',
          'viewsMin',
          'viewsMax',
          'reelPlaysMin',
          'reelPlaysMax',
          'shortsPlaysMin',
          'shortsPlaysMax',
        ]
  const allowedIds = [...allSearchParamsIds, ...idsForAmountSelect, 'postCaptions']

  useEffect(() => {
    if (currentOpenSearchParam && allowedIds?.includes(currentOpenSearchParam)) {
      const id = idsForAmountSelect?.includes(currentOpenSearchParam)
        ? searchType === searchContentTypes.CONTENT
          ? 'reach'
          : 'followers'
        : selectedPlatform === SOCIAL_PLATFORMS_NAMES.twitch.name &&
          currentOpenSearchParam === 'postCaptions'
        ? 'keywords'
        : currentOpenSearchParam

      const getActiveListItem = document.getElementById(id)
      setAnchorEl(getActiveListItem)
      setCurrId(id)
    }
  }, [currentOpenSearchParam, allowedIds?.length, selectedPlatform])

  const menuActions = searchType !== searchContentTypes.CONTENT ? creatorFilters : contentFilters

  const labelFilter = {
    [searchContentTypes.CREATOR]: labelStrings.creatorFilter,
    [searchContentTypes.BRAND]: labelStrings.brandFilter,
    [searchContentTypes.CONTENT]: labelStrings.contentFilter,
  }

  return (
    <Stack>
      <Typography
        sx={{
          fontSize: '10px',
          fontWeight: 700,
          fontFamily: 'Inter',
          textTransform: 'uppercase',
          ml: 1.5,
          color: '#442424',
        }}
      >
        {labelFilter[searchType]}
      </Typography>
      <MuiDivider sx={{ height: '1px', borderColor: '#E3C5C5', ml: 1.5 }} />
      <List dense={false}>
        {menuLinks?.map(el => {
          if (!el.id) return

          const isActiveMenuItem = finedActiveParam(el.id)
          return (
            <Tooltip arrow placement="right" title={el.isDisabled ? el.tipText : ''} key={el.id}>
              <ListItem
                aria-haspopup="true"
                onClick={event => (el?.isDisabled ? null : handlePopoverOpen(event, el.id))}
                disablePadding
                id={el.id}
                sx={{
                  '& .MuiListItemText-primary': {
                    textTransform: 'capitalize',
                    pt: '4px',
                    fontSize: '12px',
                    fontWeight: 700,
                    fontFamily: 'Inter',
                    color:
                      (currId === el.id && anchorEl) || finedActiveParam(el.id)
                        ? infludataThemes[selectedPlatform]?.platformColor
                        : '#442424',
                    '&:hover': {
                      color: '#FF5500',
                    },
                  },
                  color:
                    (currId === el.id && anchorEl) || finedActiveParam(el.id)
                      ? infludataThemes[selectedPlatform]?.platformColor
                      : '#442424',
                  '&:hover': {
                    color: infludataThemes[selectedPlatform]?.platformColor,
                    '& .MuiTypography-root': {
                      color: infludataThemes[selectedPlatform]?.platformColor,
                    },
                  },
                  backgroundColor: currId === el.id && anchorEl ? 'white' : 'transparent',
                  borderRadius: '8px',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  boxShadow:
                    currId === el.id && anchorEl
                      ? `2px 4px 5px 0px rgba(107, 69, 69, 0.20)`
                      : 'unset',
                  border:
                    currId === el.id && anchorEl
                      ? '0.5px solid #FBEBEB'
                      : '0.5px solid transparent',
                  borderRight: 'unset',
                }}
                {...(isActiveMenuItem
                  ? {
                      secondaryAction: (
                        <Box>
                          <FiberManualRecordRoundedIcon sx={{ fontSize: '12px' }} />
                        </Box>
                      ),
                    }
                  : {})}
              >
                <ListItemButton sx={{ pl: 1 }} disabled={el.isDisabled}>
                  <ListItemIcon>{el.icon}</ListItemIcon>
                  <ListItemText primary={el.name} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          )
        })}
      </List>

      <Popover
        sx={{
          '.MuiPopover-paper': {
            boxShadow: `unset`,
            borderTopRightRadius: '16px',
            borderBottomRightRadius: '16px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: '16px',
            border: '1px solid #FBEBEB',
            borderLeft: 'unset',
            p: 1,
          },
          animation: anchorEl
            ? `${animationShowSearchFilter} 0.2s ease-in-out 0s 1 normal both`
            : `${animationHideSearchFilter} 0.3s ease-in-out forwards`,
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Stack sx={{ p: 1, position: 'relative' }}>
          {!!anchorEl && (
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="start"
              sx={{ position: 'absolute', top: '-10px', right: '-8px' }}
            >
              <IconButton size="small" onClick={handlePopoverClose}>
                <CloseRoundedIcon sx={{ fill: '#6B4545', fontSize: '14px' }} />
              </IconButton>
            </Stack>
          )}
          {menuActions[currId]}
        </Stack>
      </Popover>
    </Stack>
  )
}
