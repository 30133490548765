import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  ADD_USER_TO_INFLUDATA_NAME_LENGTH,
  SOCIAL_PLATFORMS_NAMES,
  ADD_USER_FOLLOWERS_LIMIT,
} from '../../../constants/appSettings'
import { handleUsernamePrefix } from '../../../utils'

import { addProfileToInfludata } from '../../../redux/ducks/appSettingsDucks'
import { useDispatch } from 'react-redux'

import AddProfileToInfludata from './AddProfileToInfludata'
import { Box, Modal } from '@mui/material'
import AddProfileToInfludataDialog from './AddProfileToInfludataModalDialog'
import { stylesModalContent } from './styles'

const AddProfileToInfludataContainerMUI = ({
  usernameToAdd = '',
  chooseAddUserCB,
  userindex = '',
  usersFound,
  isExactUsernameFound,
  fromSearchSmartBar,
  isShowAddUserModal,
  onCloseAddUserModal,
}) => {
  const dispatch = useDispatch()
  const username = handleUsernamePrefix({ username: usernameToAdd })

  const [isModalOpened, setModalOpened] = useState(false)
  const toggleIsModalOpened = () => setModalOpened(!isModalOpened)

  const [addWithNotify, setAddWithNotify] = useState(false)
  const toggleAddWithNotify = () => setAddWithNotify(!addWithNotify)

  const [tabValue, setTabValue] = useState(SOCIAL_PLATFORMS_NAMES.instagram.name)

  const [operation, setOpeartion] = useState({
    isLoading: false,
    responseStatus: '',
  })
  const changeOperation = updateObj => setOpeartion({ ...operation, ...updateObj })

  const showAddUser = useMemo(() => {
    if (usersFound) return !isExactUsernameFound
    return (
      !usersFound &&
      username?.length >= ADD_USER_TO_INFLUDATA_NAME_LENGTH.min &&
      username?.length < ADD_USER_TO_INFLUDATA_NAME_LENGTH.max
    )
  }, [username, isExactUsernameFound, usersFound, userindex])

  const onAddUserClick = e => {
    chooseAddUserCB && chooseAddUserCB()
    toggleIsModalOpened()
  }

  const onCloseModal = () => {
    toggleIsModalOpened()
    onCloseAddUserModal && onCloseAddUserModal()
    if (operation.responseStatus) changeOperation({ responseStatus: '' })
  }

  const onConfirmAddUser = async () => {
    changeOperation({ isLoading: true })
    const responseStatus = await dispatch(
      // @ts-ignore
      addProfileToInfludata({
        profileName: username.trim(),
        withNotify: addWithNotify,
        platform: tabValue?.toLocaleLowerCase(),
      })
    )
    changeOperation({ isLoading: false, responseStatus: responseStatus || '' })
    if (!responseStatus) toggleIsModalOpened()
  }

  useEffect(() => {
    if (fromSearchSmartBar) {
      setModalOpened(isShowAddUserModal)
    }
  }, [fromSearchSmartBar, isShowAddUserModal])

  return (
    <>
      {showAddUser && !fromSearchSmartBar && (
        <AddProfileToInfludata
          onAddUserClick={onAddUserClick}
          userindex={userindex}
          fromSearchSmartBar={fromSearchSmartBar}
        />
      )}
      <Modal keepMounted open={isModalOpened} onClose={onCloseModal}>
        <Box sx={stylesModalContent}>
          <AddProfileToInfludataDialog
            close={onCloseModal}
            confirm={onConfirmAddUser}
            usernameToAdd={username}
            platform={SOCIAL_PLATFORMS_NAMES[userindex] || SOCIAL_PLATFORMS_NAMES.instagram}
            followersLimit={
              ADD_USER_FOLLOWERS_LIMIT[userindex] || ADD_USER_FOLLOWERS_LIMIT.instagram
            }
            changeAddWithNotify={toggleAddWithNotify}
            addWithNotify={addWithNotify}
            operation={operation}
            setPlatform={setTabValue}
            platformValue={tabValue}
          />
        </Box>
      </Modal>
    </>
  )
}

AddProfileToInfludataContainerMUI.propTypes = {
  addProfileToInfludata: PropTypes.func,
  usernameToAdd: PropTypes.string,
  chooseAddUserCB: PropTypes.func,
  userindex: PropTypes.string,
  usersFound: PropTypes.bool,
  isExactUsernameFound: PropTypes.bool,
  fromSearchSmartBar: PropTypes.bool,
}

export default AddProfileToInfludataContainerMUI
