import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormGroup,
  IconButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import LoadingSpinner from '../../../components/common/spinners/LoadingSpinner'
import CloseIcon from '@mui/icons-material/Close'
import {
  StyledFormControlLabel,
  StyledList,
  StyledListItem,
  StyledListItemsLabel,
  StyledModalTitle,
  StyledOperationStatusTip,
  StyledTip,
  StyledUserName,
} from './styles'
import { PlatformSwitcher } from './PlatformSwitcher'

const AddProfileToInfludataDialog = ({
  close,
  confirm,
  operation = { isLoading: false, responseStatus: '' },
  usernameToAdd,
  platform = {},
  followersLimit = 2000,
  changeAddWithNotify,
  addWithNotify = false,
  setPlatform,
  platformValue,
}) => {
  const { labelStrings } = useTranslation()

  return (
    <Card>
      {operation.isLoading && <LoadingSpinner isTransparent height="100%" width="100%" />}
      <Stack direction="row" justifyContent="end">
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <CardContent sx={{ paddingTop: 0 }}>
        <StyledModalTitle>{labelStrings.addInfluencerToInfludata}</StyledModalTitle>
        <StyledUserName color="primary">{usernameToAdd}</StyledUserName>

        <Stack direction="row" alignItems="center" spacing={1} sx={{ px: 2, mt: 2 }}>
          <StyledListItemsLabel sx={{ width: 'fit-content' }}>
            {labelStrings.pleaseSelectThePlatform}:
          </StyledListItemsLabel>
          <PlatformSwitcher setPlatform={setPlatform} platformValue={platformValue} />
        </Stack>

        {operation.responseStatus ? (
          <>
            <StyledOperationStatusTip>
              {labelStrings.addedToInfludataStatus[operation.responseStatus]}
            </StyledOperationStatusTip>
            <Stack justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
              <Button onClick={close} variant="outlined" sx={{ width: '30%' }}>
                {labelStrings.close}
              </Button>
            </Stack>
          </>
        ) : (
          <Stack sx={{ padding: '0 3%' }}>
            <StyledTip>{labelStrings.weWillAddProfileToInfludata(platform.prettyName)}</StyledTip>

            <StyledListItemsLabel>
              {labelStrings.weCantAddInfluencerToInfludataIfAccount}:
            </StyledListItemsLabel>
            <StyledList>
              <StyledListItem>
                <ListItemText primary={labelStrings.hasLessFollowers(followersLimit)} />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary={labelStrings.hasLowPosts} />
              </StyledListItem>
              <StyledListItem>
                <ListItemText primary={labelStrings.isPrivateOrRestricted} />
              </StyledListItem>
            </StyledList>

            <Typography>{labelStrings.processCanTakeFewHours}</Typography>
            <Typography>{labelStrings.shouldNotifyAboutProfileAdd}</Typography>

            <FormGroup sx={{ marginLeft: 1.5 }}>
              <StyledFormControlLabel
                onChange={changeAddWithNotify}
                checked={addWithNotify}
                control={<Checkbox />}
                label={labelStrings.notifyMe}
              />
            </FormGroup>

            <CardActions>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ width: '100%' }}
              >
                <Button onClick={close} variant="outlined" sx={{ width: '30%', marginRight: 3 }}>
                  {labelStrings.back}
                </Button>
                <Button onClick={confirm} variant="contained" sx={{ width: '30%' }}>
                  {labelStrings.send}
                </Button>
              </Stack>
            </CardActions>
          </Stack>
        )}
      </CardContent>
    </Card>
  )
}

AddProfileToInfludataDialog.propTypes = {
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  operation: PropTypes.shape({
    isLoading: PropTypes.bool,
    responseStatus: PropTypes.string,
  }),
  usernameToAdd: PropTypes.string.isRequired,
  platform: PropTypes.object,
  followersLimit: PropTypes.number,
  changeAddWithNotify: PropTypes.func,
  addWithNotify: PropTypes.bool,
}

export default AddProfileToInfludataDialog
