import React from 'react'
import { SOCIAL_LINKS_PATHS } from '../../../constants/appSettings'
import { stringifyNumToPretty } from '../../../utils'
import { Stack, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { GaleryItem, GaleryItemImage, GaleryItemOverlay } from './styles'

const LatestPostsGalleryTwItem = ({
  imgSrc = '',
  contentId = '',
  platformLink = '',
  captions = '',
  platform = 'twitch',
  views = 0,
}) => {
  return (
    <GaleryItem
      href={SOCIAL_LINKS_PATHS.twitchVideoByContentId(contentId)}
      target="_blank"
      platform={platform}
    >
      <GaleryItemImage src={imgSrc} onError={() => {}} />

      <GaleryItemOverlay platform={platform}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <VisibilityIcon sx={{ fontSize: '20px', color: 'white' }} />
          <Typography
            sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 700, color: 'white' }}
          >
            {stringifyNumToPretty(views)}
          </Typography>
        </Stack>
      </GaleryItemOverlay>
    </GaleryItem>
  )
}

export default LatestPostsGalleryTwItem
