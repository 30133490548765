import React from 'react'
import { renderToString } from 'react-dom/server'
import { Box, Stack, Typography, Chip, Tooltip } from '@mui/material'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { typographyStyles } from './commonStyle'
import Chart, { useChart } from '../../../../new-ui/components/chart'
import { fNumber } from '../../../../new-ui/utils/formatNumber'
import { pretifyBigNum } from '../../../../utils'
import { fDate } from '../../../../new-ui/utils/formatTime'
import useTranslation from '../../../../localization/useTranslation'
import InfoTip from '../../../common/tips/InfoTip'

export const CreatorMentionCharts = props => {
  const { metadata, isForPdf } = props
  const { creatorTypes, reachEmvBarGraphData, creatorScores, averageOverallPerformance } = metadata || {}
  const { labelStrings } = useTranslation()

  const reachEmvGraphDates = reachEmvBarGraphData?.map(el => el.date)
  const reachEmvGraphValues = reachEmvBarGraphData?.map(el => el.value)
  const reachEmvGraphReach = reachEmvBarGraphData?.map(el => el.reach)

  const donutChartValues = Object.values(creatorTypes)

  const donutChartTotalValue = Object.values(creatorTypes).reduce(
    (sum, current) => sum + current,
    0
  )
  const donutChartLabels = Object.entries(creatorTypes).map(([key, value]) => {
    const percent = Math.round((value / donutChartTotalValue) * 100)
    return renderToString(
      <Typography sx={{ color: '#784E4E', m: 0, p: 0 }}>
        {key.toUpperCase()} <span style={{ fontWeight: 'bold' }}>{percent}%</span>{' '}
        <span style={{ fontSize: '10px', fontWeight: 600 }}>
          ({value} {labelStrings.brandsTimelineText.creators})
        </span>
      </Typography>
    )
  })

  const donutCreatorsScoresChartLabels = Object.entries(creatorScores?.values).map(
    ([key, value]) => {
      const percent = Math.round((value / donutChartTotalValue) * 100)
      return renderToString(
        <Typography sx={{ color: '#784E4E', m: 0, p: 0 }}>
          {key.replace(/([a-z])([A-Z])/g, '$1 $2')?.toUpperCase()}{' '}
          <span style={{ fontWeight: 'bold' }}>{percent}%</span>{' '}
          <span style={{ fontSize: '10px', fontWeight: 600 }}>
            ({value} {labelStrings.brandsTimelineText.creators})
          </span>
        </Typography>
      )
    }
  )

  const donutCreatorScoresValues = Object.values(creatorScores?.values)

  const barChartOptions = type =>
    useChart({
      tooltip: {
        marker: { show: false },
        y: {
          formatter: value => `${fNumber(value)} ${type === 'emv' ? 'EUR' : 'Views'}`, // Append "Views" to each value
          title: {
            formatter: () => '',
          },
        },
      },
      chart: {
        type: 'bar',
      },
      xaxis: {
        categories: reachEmvGraphDates,
        labels: {
          formatter: function(value) {
            return `${fDate(value, 'MMM yy')}`
          },
          style: {
            colors: Array.from({ length: reachEmvGraphDates?.length }, () => '#784E4E'),
          },
        },
        axisBorder: {
          show: true,
          color: '#CBB3B3',
          offsetX: 0,
          offsetY: 0,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
          colorStops: [
            {
              offset: 0.35,
              color: '#FFBC00',
              opacity: 1,
            },
            {
              offset: 100,
              color: '#FF5D5A',
              opacity: 1,
            },
          ],
        },
      },
      yaxis: {
        show: true,
        axisBorder: {
          show: true,
          color: '#CBB3B3',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: function(value) {
            return `${pretifyBigNum(value)}`
          },
          style: {
            colors: Array.from({ length: reachEmvGraphDates?.length }, () => '#784E4E'),
          },
        },
      },
      grid: {
        show: false,
      },
      stroke: {
        show: false,
      },
    })

  const donutChartOptions = {
    chart: {
      type: 'donut',
    },
    tooltip: {
      enabled: false,
      marker: { show: false },
      y: {
        formatter: value => pretifyBigNum(value),
        title: {
          formatter: () => '',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function(val) {
        return `${Math.round(val)}%`
      },
      style: {
        fontSize: '15px',
        fontFamily: 'Inter',
        fontWeight: 700,
        colors: Array.from({ length: donutChartValues?.length }, () => '#FFF'),
      },
    },
    colors: ['#FF5D5A', '#FFBC00', '#CBB3B3', '#784E4E'],
    labels: donutChartLabels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
        },
      },
    },
    legend: {
      position: 'left', // must be one of: 'bottom', 'left', 'top', 'right'
      horizontalAlign: 'center',
      offsetY: 0,
      height: undefined,
      markers: {
        radius: 3,
        width: 18,
        height: 18,
        offsetY: 0,
        offsetX: 0,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5
      },
      containerMargin: {
        left: 0,
        top: 0
      },
      formatter: function(seriesName, opts) {
        // This helps ensure the label and marker are properly aligned
        return `<div style="display: flex; align-items: center;">${seriesName}</div>`
      },
      fontSize: '13px',
      fontWeight: 400,
      fontFamily: 'Inter',
      onItemHover: {
        highlightDataSeries: false,
      },
    },
  }

  const donutCreatorScoresOptions = {
    chart: {
      type: 'donut',
    },
    tooltip: {
      enabled: false,
      marker: { show: false },
      y: {
        formatter: value => pretifyBigNum(value),
        title: {
          formatter: () => '',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function(val) {
        return `${Math.round(val)}%`
      },
      style: {
        fontSize: '15px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 700,
      },
    },
    colors: ['#057B2D', '#6CB75B', '#FFBC00', '#FF8D06', '#EA3323']?.reverse(),
    labels: donutCreatorsScoresChartLabels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: '18px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 700,
              color: '#784E4E',
              offsetY: -5,
              formatter: function(val) {
                return val
              },
            },
            total: {
              show: true,
              showAlways: true,
              fontFamily: 'Inter, sans-serif',
              fontWeight: 700,
              color: '#784E4E',
              label: 'Median',
              formatter: function(w) {
                return creatorScores?.median
              },
            },
          },
        },
      },
    },
    legend: {
      position: 'left', // must be one of: 'bottom', 'left', 'top', 'right'
      horizontalAlign: 'center',
      offsetY: 0,
      markers: {
        radius: 3,
        width: 18,
        height: 18,
        offsetY: 0,
        offsetX: 0,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5
      },
      containerMargin: {
        left: 0,
        top: 0
      },
      formatter: function(seriesName, opts) {
        // This helps ensure the label and marker are properly aligned
        return `<div style="display: flex; align-items: center;">${seriesName}</div>`
      },
      fontSize: '13px',
      fontWeight: 400,
      fontFamily: 'Inter',
      onItemHover: {
        highlightDataSeries: false,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  }

  return (
    <Stack sx={{ width: '55%' }} direction="column" spacing={1}>
      <Stack sx={{ width: '100%', height: '100%' }} spacing={2}>
        {averageOverallPerformance && (
          <Stack spacing={0.1} sx={{ mb: '24px !important' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography sx={typographyStyles}>
                {labelStrings.brandsTimelineText?.averageOverallPerformance || 'Average Overall Performance'}
              </Typography>
              {!isForPdf && (
                <InfoTip>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `This is the average performance index across all creators and their content in this campaign.`,
                    }}
                  />
                </InfoTip>
              )}
            </Stack>
            <Box sx={{ mt: 1 }}>
              <Tooltip title={labelStrings.brandsTimelineText?.tooltipPerformance || 'Performance Index'}>
                <Chip
                  size="medium"
                  icon={<TrendingUpIcon style={{ 
                    color: averageOverallPerformance >= 0 ? 'green' : 'red',
                    transform: averageOverallPerformance >= 0 ? 'none' : 'scaleY(-1)'
                  }} />}
                  label={`${Math.round(averageOverallPerformance * 100)}%`}
                  variant="outlined"
                  sx={{
                    '& .MuiChip-label': {
                      color: averageOverallPerformance >= 0 ? 'green' : 'red',
                      fontWeight: 'bold',
                      fontSize: '14px'
                    }
                  }}
                />
              </Tooltip>
            </Box>
          </Stack>
        )}
        
        <Stack spacing={0.1} sx={{ mb: '24px !important' }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={typographyStyles}>
              {labelStrings.brandsTimelineText.creatorInfludataScores}
            </Typography>
            {!isForPdf && (
              <InfoTip>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `These are the combined "influData Overall Scores" of the creators within all collaborations. A higher average score means a higher quality of creators.
                              Score Buckets: </br>
                              Very Low: 3 or less </br>
                              Low: Score 3-4.9 </br>
                              Medium: 5-6.9 </br>
                              High: 7-8.4 </br>
                              Very High: 8.5 or more`,
                  }}
                />
              </InfoTip>
            )}
          </Stack>

          <Stack
            sx={{
              '& .apexcharts-legend': {
                padding: '0 !important',
              },
              '& .apexcharts-legend-series': {
                display: 'flex !important',
                margin: '0 5px !important',
                alignItems: 'center !important',
                paddingTop: '5px !important',
                paddingBottom: '5px !important',
              },
              '& .apexcharts-legend-text': {
                color: '#784E4E !important',
                lineHeight: '18px !important',
                height: '18px !important',
                display: 'inline-flex !important',
                alignItems: 'center !important',
                paddingLeft: '5px !important',
              },
              '& .apexcharts-legend-marker': {
                marginRight: '5px !important',
                marginTop: '0 !important',
                verticalAlign: 'middle !important',
                alignSelf: 'center !important',
              },
              '& .apx-legend-position-left': {
                top: '0 !important',
                justifyContent: 'center !important',
              },
              marginTop: '-70px',
            }}
          >
            <Chart
              type="donut"
              series={donutCreatorScoresValues}
              options={donutCreatorScoresOptions}
              height={250}
            />
          </Stack>
        </Stack>

        <Stack spacing={0.1} sx={{ mb: '24px !important' }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography sx={typographyStyles}>
              {labelStrings.brandsTimelineText.creatorsType}
            </Typography>
            {!isForPdf && (
              <InfoTip>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `NANO < 10k Followers
                          MICRO < 100k Followers
                          MACRO < 500k Followers
                          MEGA > 500k Followers`,
                  }}
                />
              </InfoTip>
            )}
          </Stack>

          <Stack
            sx={{
              '& .apexcharts-legend': {
                padding: '0 !important',
              },
              '& .apexcharts-legend-series': {
                display: 'flex !important',
                margin: '0 5px !important',
                alignItems: 'center !important',
                paddingTop: '5px !important',
                paddingBottom: '5px !important',
              },
              '& .apexcharts-legend-text': {
                color: '#784E4E !important',
                lineHeight: '18px !important',
                height: '18px !important',
                display: 'inline-flex !important',
                alignItems: 'center !important',
                paddingLeft: '5px !important',
              },
              '& .apexcharts-legend-marker': {
                marginRight: '5px !important',
                marginTop: '0 !important',
                verticalAlign: 'middle !important',
                alignSelf: 'center !important',
              },
              '& .apx-legend-position-left': {
                top: '0 !important',
                justifyContent: 'center !important',
              },
              marginTop: '-70px',
            }}
          >
            <Chart
              type="donut"
              series={donutChartValues}
              options={donutChartOptions}
              height={250}
            />
          </Stack>
        </Stack>

        <Box dir="ltr">
          <Stack>
            <Typography sx={{ ...typographyStyles, mb: 1 }}>
              {labelStrings.brandsTimelineText.reachFromCreatorsLabelChart}
            </Typography>
            <Box>
              <Chart
                type="bar"
                series={[{ data: reachEmvGraphReach }]}
                options={barChartOptions('reach')}
                height={188}
              />
            </Box>
          </Stack>
        </Box>

        <Box dir="ltr">
          <Stack sx={{ position: 'relative' }}>
            <Typography sx={{ ...typographyStyles, mb: 1 }}>
              {labelStrings.brandsTimelineText.emvLabelChart}
            </Typography>
            <Chart
              type="bar"
              series={[{ data: reachEmvGraphValues }]}
              options={barChartOptions('emv')}
              height={188}
            />
            <Typography
              sx={{
                position: 'absolute',
                bottom: '25px',
                left: '35px',
                fontSize: '11px',
                color: '#784E4E',
              }}
            >
              €
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  )
}
