import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'
import { Stack, Typography } from '@mui/material'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import { StyledBtnClickHere, TextWrapper } from './styles'

const AddProfileToInfludata = ({ onAddUserClick, fromSearchSmartBar }) => {
  const { labelStrings, currentLang } = useTranslation()

  if (fromSearchSmartBar) {
    return (
      <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%', px: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <PersonAddAltRoundedIcon sx={{ fontSize: '24px' }} />
          <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 600 }}>
            {labelStrings.notInHereYet}? {labelStrings.noProblem}!
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ width: 'fit-content' }}>
          <Stack direction="row" alignItems="center">
            <StyledBtnClickHere
              sx={{ fontSize: '16px', p: 0, fontWeight: 500 }}
              variant="text"
              onClick={onAddUserClick}
            >
              <Stack direction="row" alignItems="center">
                {labelStrings.clickHere} {labelStrings.toAddUserToInfludata}
              </Stack>
            </StyledBtnClickHere>
          </Stack>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      <TextWrapper>
        {labelStrings.notInHereYet}? {labelStrings.noProblem}!
      </TextWrapper>

      <Stack
        direction={currentLang === 'de' ? 'column' : 'row'}
        alignItems="center"
        justifyContent="center"
        sx={{ width: '100%' }}
      >
        <Stack direction="row" alignItems="center">
          <PersonAddAltRoundedIcon />
          <StyledBtnClickHere variant="text" onClick={onAddUserClick}>
            {labelStrings.clickHere}
          </StyledBtnClickHere>
        </Stack>

        <TextWrapper>{labelStrings.toAddUserToInfludata}</TextWrapper>
      </Stack>
    </Stack>
  )
}

AddProfileToInfludata.propTypes = {
  onAddUserClick: PropTypes.func,
}

export default AddProfileToInfludata
