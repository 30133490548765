import { isAfter, subHours, differenceInDays } from 'date-fns'
import countryCodeList from 'country-codes-list'
import { isEuMember } from 'is-eu-member'
import langs from 'langs'
import {
  SOCIAL_PLATFORMS_NAMES,
  NAVBAR_HEIGHT,
  TWENTY_FOUR_HOURS_MS,
  PROTOCOLS,
  INFLUDATA_HOSTS,
} from '../constants/appSettings'
import { SORTING_DIRECTIONS } from '../constants/sortings'
import { EMAIL_VALID_REGEX, PHONE_VALID_REGEX } from '../constants/login'
import { PRO_PACKAGE_REPORTS_PLANS } from '../constants/user'
import { COMPARED_LOADING } from '../constants/comparedProfiles'
import availableLangs from '../localization/availableLangs'
import routes from '../routes'

export function formatFollowers(value) {
  if (value > 1000000) {
    return `${(value / 1000000).toFixed(1).toLocaleString()}m`
  }
  if (value > 1000) {
    return `${(value / 1000).toFixed(1).toLocaleString()}k`
  } else return value
}

export const parseArrValuesToString = arr => {
  return arr && arr.length
    ? arr.reduce((acc, el, key) => `${acc}${key && el.value ? ',' : ''}${el.value}`, '')
    : ''
}

export const parseStringToArrOfObjects = csvString =>
  csvString && csvString.split(',').reduce((arr, el) => [...arr, { value: el, label: el }], [])

export const changeProfileInProfilesList = ({
  profileId,
  changeField,
  newValue,
  profilesList = [],
}) => {
  if (!profilesList.length) return profilesList
  return profilesList.map(user =>
    user._id === profileId ? { ...user, [changeField]: newValue } : user
  )
}

export const isProfileExistsInProfilesList = (profileId = '', profilesList = []) =>
  profilesList.some(u => u._id === profileId)

export const filterSelectedUsersFromUsersList = usersArr =>
  usersArr?.length ? usersArr?.filter(user => user?.isSelected) : []

export const delayFunc = (func, time = 200) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(), time)
  }).then(() => func())
}

export const createProfileEngagementData = engagementMean => {
  if (!engagementMean) return { value: 0, text: '0%' }
  const splitted = engagementMean.split(' ') // get number and % symbol in sepatate
  return {
    value: splitted[0] ? splitted[0] / 100 : 0,
    text: splitted[0] ? `${splitted[0]}%` : '0%',
  }
}

export const prepareUserGrant = userContext => {
  const { grants, props } = userContext
  return {
    ...grants?.reduce((obj, item) => {
      obj[item] = true
      return obj
    }, {}),
    ...(!!props && props),
  }
}

export const hasInfludataCookie = () => document && document.cookie.includes('whoami')

export const pretifyBigNum = (num, roundCount = 1, isRoundUp = true) => {
  if (num === 0) return 0
  if (!num) return ''
  if (typeof num !== 'number') return null

  const billion = 1000000000
  const million = 1000000
  const thousand = 1000
  const hundredths = 999
  const tenths = 10

  if (Math.abs(num) >= billion)
    return `${
      num % billion === 0 ? (num / billion).toFixed(0) : (num / billion).toFixed(roundCount)
    }b`
  if (Math.abs(num) >= million)
    return `${
      num % million === 0 ? (num / million).toFixed(0) : (num / million).toFixed(roundCount)
    }m`
  if (Math.abs(num) >= thousand)
    return `${
      num % thousand === 0 ? (num / thousand).toFixed(0) : (num / thousand).toFixed(roundCount)
    }k`
  if (Math.abs(num) < tenths) {
    return Math.round(num * 10) / 10
  }
  if (Math.abs(num) < hundredths) {
    return Math.round(num)
  }

  const fraction = Math.pow(10, roundCount)
  return isRoundUp ? Math.round(num * fraction) / fraction : num
}

export const stringifyNumToPretty = num => {
  return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0
}

export const validateEmail = email => {
  if (email.includes('+')) return false
  return EMAIL_VALID_REGEX.test(String(email).toLowerCase())
}

export const validatePhone = phone => {
  if (!phone) {
    return false
  }
  const preparedString = phone.trim()
  const tested = PHONE_VALID_REGEX.test(preparedString)
  return tested
}

export const validatePassword = ({ password, minLetters = 6, withUpperCase = true }) => {
  if (!password) return false
  if (password.length < minLetters) return false // less than minLetters
  if (withUpperCase && password.toLowerCase() === password) return false // no big letters
  return true
}

export const throttled = (fn, delay = 1000) => {
  let lastCall = 0
  return function(...rest) {
    const now = Date.now()

    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...rest)
  }
}

export const debounced = (fn, delay = 1000) => {
  let timerId
  return function(...args) {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}

export const debouncedAsync = (fn, delay = 1000) => {
  let timerId
  return async function(...args) {
    if (timerId) {
      clearTimeout(timerId)
    }
    return await new Promise((resolve, reject) => {
      timerId = setTimeout(() => {
        const response = fn(...args)
        resolve(response)
        timerId = null
      }, delay)
    })
  }
}

export const getNavbarHeight = () => {
  const navbar = !!document ? document.getElementById('navbar') : null
  const { height } = navbar ? navbar.getBoundingClientRect() : { height: NAVBAR_HEIGHT }
  return height
}

export const sortObjectByValues = ({ obj, direction = false }) => {
  // direction: true => from smaller to bigger; false => from bigger to smaller. Default => from bigger to smaller
  const sortedKeys = Object.keys(obj).sort((a, b) =>
    direction ? obj[a] - obj[b] : obj[b] - obj[a]
  )

  return sortedKeys.reduce((returnObj, key) => {
    returnObj[key] = obj[key]
    return returnObj
  }, {})
}

export const formatUnixToMDY = (uTime, currentlang) => {
  const msTime = uTime * 1000
  return new Date(msTime).toLocaleDateString(currentlang, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })
}

export const checkValTo10 = v => (v < 10 ? `0${v}` : v)

export const formatDateToLocale = ({ dateStr = '', currentLang = 'US' }) => {
  if (!dateStr || typeof dateStr !== 'string') return ''
  try {
    const date = new Date(dateStr)
    if (currentLang.toUpperCase() === 'EN') {
      currentLang = 'US'
    }
    if (isNaN(date)) return '' // Invalid date check

    const formats = {
      US: () => {
        // MM/DD/YYYY
        const month = date.getMonth() + 1
        const day = date.getDate()
        const year = date.getFullYear()
        return `${month}/${day}/${year}`
      },
      UK: () => {
        // DD/MM/YYYY
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      },
      ISO: () => {
        // YYYY-MM-DD (ISO 8601)
        return date.toISOString().split('T')[0]
      },
      DE: () => {
        // DD.MM.YYYY (German)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day}.${month}.${year}`
      },
      JP: () => {
        // YYYY/MM/DD (Japanese)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return `${year}/${month}/${day}`
      },
      CN: () => {
        // YYYY年MM月DD日 (Chinese)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return `${year}年${month}月${day}日`
      },
      FR: () => {
        // DD/MM/YYYY (French)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day}/${month}/${year}`
      },
      // Add more country formats as needed
    }

    const formatter = formats[currentLang.toUpperCase()]
    console.log('CUURRR LANF', currentLang)
    if (typeof formatter !== 'function') return '' // Invalid format

    return formatter()
  } catch (err) {
    return ''
  }
}

export const checkIfTimeHasPassed = ({ dateStr = '', gap = TWENTY_FOUR_HOURS_MS }) => {
  // by default we use 24h gap
  if (!dateStr) return false
  try {
    const now = Date.now()
    const givenDateMs = new Date(dateStr).getTime()
    const givenDateMsGapSum = givenDateMs + gap
    return givenDateMsGapSum < now
  } catch (err) {
    return false
  }
}

export const getExoticLangName = language => {
  const lang = language.includes('-') ? language.split('-')[0] : language
  const foundLang = langs.where('1', lang)
  return foundLang ? foundLang.name : undefined
}

export const encodeBtoa = v => (window && window.btoa ? window.btoa(v) : v)
export const decodeBtoa = v => (window && window.atob ? window.atob(v) : v)

export const notExistingValue = v => v === null || v === undefined || v === NaN

// sorting objects func
export const sortArrayOfObjects = ({
  array = [],
  sortKey = '',
  sortDirection = '',
  mutate = false,
  transformStringToNumber = false,
}) => {
  if (!array.length || !sortKey || !sortDirection) return array
  const copyArr = mutate ? array : [...array]

  const sortStrings = (prev, next) => {
    if (prev < next) return sortDirection === SORTING_DIRECTIONS.ascend ? -1 : 1
    if (prev > next) return sortDirection === SORTING_DIRECTIONS.ascend ? 1 : -1
    return 0
  }

  const sortNumbers = (prev, next) =>
    sortDirection === SORTING_DIRECTIONS.ascend ? prev - next : next - prev

  const isNestedKey = sortKey.includes('.')
  const nestedSortKeys = isNestedKey ? sortKey.split('.') : []

  const getSortItemElementValue = element => {
    if (isNestedKey) {
      let candidate = null
      for (const key of nestedSortKeys) {
        candidate = candidate ? candidate[key] : element[key]
      }
      return candidate
    }

    const value = element[sortKey]

    // Check for engagement Mean to leave '%' when sorting
    if (sortKey === 'engagementMean' && typeof value === 'string') {
      const numericValue = parseFloat(value.replace('%', '')) //Convert a string to a number
      return {
        originalValue: value, // Keep the original string with '%'
        numericValue, // Use only number for sorting
      }
    }

    return transformStringToNumber ? parseFloat(value) : value
  }

  copyArr.sort((prevElement, nextElement) => {
    const prev = getSortItemElementValue(prevElement)
    const next = getSortItemElementValue(nextElement)

    // Check that we are working with engagementMean if it is an object
    if (sortKey === 'engagementMean' && prev.originalValue && next.originalValue) {
      // Sort by numeric values, but keep the original value
      if (prev.numericValue < next.numericValue) {
        return sortDirection === SORTING_DIRECTIONS.ascend ? -1 : 1
      }
      if (prev.numericValue > next.numericValue) {
        return sortDirection === SORTING_DIRECTIONS.ascend ? 1 : -1
      }
      return 0
    }

    if (notExistingValue(prev) && !notExistingValue(next)) {
      return typeof next === 'string' ? sortStrings('', next) : sortNumbers(0, next)
    }

    if (!notExistingValue(prev) && notExistingValue(next)) {
      return typeof prev === 'string' ? sortStrings(prev, '') : sortNumbers(prev, 0)
    }

    if (typeof prev === 'string' && typeof next === 'string') {
      return sortStrings(prev, next)
    }
    return sortNumbers(prev, next)
  })

  // Restore the original value for engagementMean
  copyArr.forEach(element => {
    const value = element[sortKey]
    if (sortKey === 'engagementMean' && typeof value === 'object' && value.originalValue) {
      element[sortKey] = value.originalValue // Restore the string with '%'
    }
  })

  return copyArr
}

export const parseSearchQuerySorting = ({ querySorting, separator = ',' }) => {
  if (!querySorting) return {}
  if (querySorting.includes(separator)) {
    const [sortDirection, sortKey] = querySorting.split(separator)
    return { sortDirection, sortKey }
  }
  return { sortKey: querySorting }
}

export const findSocialPlatformIndex = currentPlatform => {
  const platformObj = Object.values(SOCIAL_PLATFORMS_NAMES).find(el => el.name === currentPlatform)
  return !!platformObj ? platformObj.index : ''
}

export const checkIsUrlWithHttp = url => {
  try {
    const newUrl = new URL(url)
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
  } catch (err) {
    return false
  }
}

export const constructLinkToWECFileServer = path => {
  if (!path || typeof path !== 'string') return ''
  if (checkIsUrlWithHttp(path)) return path
  return `${INFLUDATA_HOSTS.fileServer}${path.startsWith('/') ? path : `/${path}`}`
}

export const validateOnlyDigits = str => /^\d+$/.test(str)
export const encodeStringForFormData = str => {
  const symbolsToEncode = ['&', '?']
  return str
    .split()
    .map(s => (symbolsToEncode.includes(s) ? encodeURI(s) : s))
    .join()
}

export const convertInvoiceAmountFromCents = amount => {
  const INVOICE_CENTS_DIVISION = 100
  const amountStr = (amount / INVOICE_CENTS_DIVISION).toFixed(2)
  return amountStr.split('.').join(',') // replace . with ,
}

export const getCountryNameByCode = (countryCode = '') => {
  const country = countryCodeList.findOne('countryCode', countryCode)
  return country ? country.countryNameEn : ''
}

export const getCountryCodeByName = (countryName = '') => {
  const country = countryCodeList.findOne('countryNameEn', countryName)
  return country ? country.countryCode : ''
}

export const getIsEuropeanCountrySelected = (countryCode = '') => isEuMember(countryCode)

export const closuredItemFactory = function() {
  let _closured = null
  return {
    getItem() {
      return _closured
    },
    setItem(newValue) {
      _closured = newValue
    },
  }
}

export const scrollElementIntoView = elementId => {
  const targetEl = document.getElementById(elementId)
  if (targetEl) {
    targetEl.scrollIntoView({ behavior: 'smooth' })
  }
}

export const mapObjectKeysToKeyValPairs = obj =>
  Object.keys(obj).reduce((keys, key) => ({ ...keys, [key]: key }), {})

export const calcAnnualPrice = (monthPrice = 0) => {
  const priceForCalc = typeof monthPrice === 'number' ? monthPrice : parseInt(monthPrice, 10)
  if (!priceForCalc) return 0
  const annualPrice = priceForCalc * 12
  const result = Math.round(annualPrice)
  return result
}

export const getTokenPriceDiffLabel = ({
  reportPlanId,
  selectedReportPlanId,
  isProPlanBilledYearly,
}) => {
  if (reportPlanId === selectedReportPlanId) return '+0'

  if (isProPlanBilledYearly) {
    const yearReportsPlans = PRO_PACKAGE_REPORTS_PLANS.year
    switch (selectedReportPlanId) {
      case yearReportsPlans.reports300:
        if (reportPlanId === yearReportsPlans.reports1200) return '+200'
        if (reportPlanId === yearReportsPlans.reports1600) return '+600'
      case yearReportsPlans.reports1200:
        if (reportPlanId === yearReportsPlans.reports300) return '-200'
        if (reportPlanId === yearReportsPlans.reports1600) return '+400'
      case yearReportsPlans.reports1600:
        if (reportPlanId === yearReportsPlans.reports300) return '-600'
        if (reportPlanId === yearReportsPlans.reports1200) return '-200'
    }
  } else {
    const days90ReportsPlans = PRO_PACKAGE_REPORTS_PLANS.days90
    switch (selectedReportPlanId) {
      case days90ReportsPlans.reports75:
        if (reportPlanId === days90ReportsPlans.reports300) return '+1500'
        if (reportPlanId === days90ReportsPlans.reports1500) return '+4500'
      case days90ReportsPlans.reports300:
        if (reportPlanId === days90ReportsPlans.reports75) return '-1500'
        if (reportPlanId === days90ReportsPlans.reports1500) return '+3000'
      case days90ReportsPlans.reports1500:
        if (reportPlanId === days90ReportsPlans.reports75) return '-4500'
        if (reportPlanId === days90ReportsPlans.reports300) return '-1500'
    }
  }
}

export const handleUsernamePrefix = ({ username = '', prefix = '@' }) => {
  if (!username) return username
  if (username.startsWith(prefix)) {
    const sliced = username.slice(prefix.length)
    return sliced
  }

  return username
}

export const sortComparedProfiles = ({
  profiles = null,
  sortKey = '',
  sortDir = '',
  onlyLoaded = true,
} = {}) => {
  const defaultSortedProfilesObj = {}
  if (!profiles || !sortKey || !sortDir) return defaultSortedProfilesObj
  const profilesArr = Object.values(profiles)

  if (onlyLoaded) {
    const isAnyLoading = profilesArr.some(p => !!p[COMPARED_LOADING])
    if (isAnyLoading) return profiles // we dont do sorting until all profiles are loaded
  }

  const sortedArr = sortArrayOfObjects({
    array: profilesArr,
    sortKey,
    sortDirection: sortDir,
  })
  return sortedArr.reduce(
    (_obj, u) => (u[COMPARED_LOADING] ? _obj : { ..._obj, [u._id]: u }),
    defaultSortedProfilesObj
  )
}

export const notEmptyArray = arr => !!arr && !!arr.length

export const notEmptyObject = obj => !!obj && !!Object.keys(obj).length

export const isEmptyObjectProperty = obj => {
  if (!obj) return

  const values = Object.values(obj)

  return values?.some(el => {
    return el !== null && el?.length !== 0
  })
}

export function throttle(func, ms = 5000) {
  let isThrottled = false,
    savedArgs,
    savedThis

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments
      savedThis = this
      return
    }
    func.apply(this, arguments)
    isThrottled = true
    setTimeout(function() {
      isThrottled = false
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs)
        savedArgs = savedThis = null
      }
    }, ms)
  }

  return wrapper
}

export const convertHexToRgba = (hex, alpha = 1) => {
  try {
    const [r, g, b] = hex?.match(/\w\w/g).map(x => parseInt(x, 16))
    return `rgba(${r}, ${g}, ${b}, ${alpha})`  
  } catch (e) {
    console.log('Error with convertHexToRgba', e)
    return `rgba(${0}, ${0}, ${0}, ${1})`  
  }
}

export const copyToClipboard = text => {
  const dummy = document.createElement('input')
  document.body.appendChild(dummy)
  dummy.setAttribute('value', text)
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)
}

export const generateRandomId = () =>
  `_${Math.random()
    .toString(36)
    .substr(2, 9)}`

export const checkIsUrlValid = str => {
  try {
    new URL(str)
    return true
  } catch (err) {
    return false
  }
}

export const formatBenchmarkValue = value => {
  if (!value) return value
  if (String(value).includes('%')) return value
  if (!Number.isNaN(+value)) return pretifyBigNum(+value, 1, false)

  const equalityOptions = ['<=', '>=', '<', '>']
  let returnedValue = value

  for (let equalityOption of equalityOptions) {
    if (value.includes(equalityOption)) {
      const onlyValue = value.slice(equalityOption.length)
      const formattedOnlyValue = !Number.isNaN(+onlyValue)
        ? pretifyBigNum(+onlyValue, 1, false)
        : onlyValue
      returnedValue = `${equalityOption} ${formattedOnlyValue}`
      break
    }
  }

  return returnedValue
}

export const replaceEqualityOptionWithSymbol = str =>
  str
    .replace('>=', '&#8805;')
    .replace('<=', '&#8804;')
    .replace('!=', '&#8800;')
    .replace('!==', '&#8800;')

export const cutStringToMaxLength = ({ str = '', maxLength = 100, withEllipsis = true }) => {
  if (str.length <= maxLength) return str
  let cuttedStr = str.slice(0, maxLength - 3)
  if (withEllipsis) cuttedStr += '...'
  return cuttedStr
}

export const constructLinkToLandingPage = (page, currentLang) => {
  let link = INFLUDATA_HOSTS.infludata
  if (!!currentLang && !!availableLangs[currentLang] && currentLang !== availableLangs.en) {
    link += `/${currentLang}`
  }
  if (!!page) {
    link += `/${page}`
  }
  return link
}

export const convertValueToPercentage = value => {
  return value && ((value / 1) * 100).toFixed(1) + '%'
}

export const getUrlWithProxy = (url = '') => {
  return `${window.location.origin}${routes.collab.api.proxy}/${url}`
}

export const replaceAllEmojiInStr = str => {
  return str
    ?.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
    ?.replace(/\s+/g, ' ')
}
export const findKeyOrValueInObjList = (objList, searchValue) => {
  if (undefined === searchValue || searchValue === '') return objList
  return objList?.filter(item => {
    let flag
    for (let prop in item) {
      flag = false
      flag =
        item[prop]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf(searchValue?.toLowerCase()) > -1
      if (flag) break
    }
    return flag
  })
}

export const comparePlatform = data => {
  if (!data.length) {
    return false
  }

  const firstIndex = data[0]._index

  for (let i = 1; i < data.length; i++) {
    if (data[i]._index !== firstIndex) {
      return false
    }
  }
  return true
}

export const countUnlockedOn = data => {
  let notUnlockedCount = 0
  if (!data.length) {
    return -1
  }
  for (const item of data) {
    if (!item.unlockedOn || item.unlockedOn === '') {
      notUnlockedCount++
    }
  }
  return notUnlockedCount
}

export const findCountryIsoCode = (countryArray, findCountry) => {
  if (!findCountry || typeof findCountry !== 'string') return

  let value = findCountry

  if (value?.toLowerCase() === 'united states' || value?.toLowerCase() === 'usa') {
    value = 'United States Of America'
  }

  if (value?.toLowerCase() === 'arab emirates' || value?.toLowerCase() === 'united emirates') {
    value = 'United Arab Emirates'
  }

  return countryArray?.find(country => country?.value?.toLowerCase() === value?.toLowerCase())
    ?.isoCode
}

export const getPngCountryFlags = (countryIsoCode, country) => {
  if (!countryIsoCode) return

  return (
    <img
      src={`https://flagcdn.com/16x12/${countryIsoCode?.toLowerCase()}.png`}
      alt={country}
      style={{ marginRight: '5px', width: '16px', height: '12px' }}
    />
  )
}

export const checkDateIsLessThan24HoursBefore = dateToCheck => {
  const twentyFourHoursAgo = subHours(new Date(), 24)
  return isAfter(new Date(dateToCheck), twentyFourHoursAgo)
}

export const daysUntilDate = date => {
  if (!date) return 0

  let targetDate
  if (typeof date === 'string' && date.includes('.')) {
    // Parse DD.MM.YYYY format
    const [day, month, year] = date.split('.').map(Number)
    targetDate = new Date(year, month - 1, day)
  } else {
    // Parse as UNIX timestamp (in seconds)
    const timestamp = typeof date === 'string' ? parseInt(date, 10) : date
    targetDate = new Date(timestamp * 1000)
  }

  const currentDate = new Date()
  const daysDifference = differenceInDays(targetDate, currentDate)

  return daysDifference
}

export const findParameterValue = (query, param) => {
  const params = new URLSearchParams(query)
  return params.get(param) ? params.get(param)?.toUpperCase() : 'INSTAGRAM'
}