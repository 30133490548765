import React, { useRef, useState, useMemo, useCallback } from 'react'
import { Stack, Box, Chip, Typography, Paper } from '@mui/material'
import {
  capitalizeFirstLetter,
  defaultAmountQueryValues,
  defaultSearchQuery,
  findItemsByKeyValue,
  searchParamsName,
} from '../utils'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { ChipPlatforms } from './ChipPlatforms'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { changeAppSocialPlatform } from '../../../../redux/ducks/appSettingsDucks'
import { searchTypeSelector, selectedPlatformSelector } from '../../../../redux/selectors'
import useTranslation from '../../../../localization/useTranslation'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { pretifyBigNum } from '../../../../utils'
import { animationHidePlatformChip, animationShowPlatformChip } from '../../../theme/animations'
import { updateSearch } from '../../../../redux/ducks/searchDucks'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'
import { searchParamsIcons } from '../icons'
import { searchContentTypes } from '../../../../constants/search'

export const SearchChip = ({
  searchParam,
  removeSearchParamsFromQuery,
  addSuggestions,
  currentQuery,
  setCurrentOpenSearchParam,
  isContentType,
  currentPlatform,
}) => {
  const dispatch = useDispatch()
  const { labelStrings, languageOptions, genderOptions } = useTranslation()
  const selectedPlatform = useSelector(selectedPlatformSelector, shallowEqual)
  const searchType = useSelector(searchTypeSelector)

  const isBrandType = searchType === searchContentTypes.BRAND 

  // Extract key/value from searchParam
  const keyName = Object.keys(searchParam)[0]
  const searchValue = Object.values(searchParam)[0]

  // If this param was created as a 'suggestion', we might have an extra key in the object
  const isSuggestion = Object.keys(searchParam)?.length > 1
  const isFindTheSameParams = findItemsByKeyValue(
    currentQuery,
    searchParam?.key,
    searchParam?.value
  )

  // For suggestions, the "real" key is searchParam.key, and the "real" value is searchParam.value
  const suggestionsKey = searchParam?.key || ''
  const suggestionsValue = searchParam?.value || ''

  const value = isSuggestion ? suggestionsValue : searchValue
  const key = isSuggestion ? suggestionsKey : keyName

  // Use label arrays from translation
  const languageLabel =
    key === 'language' && (languageOptions?.find(lang => lang.value === value)?.label || value)

  const genderLabel =
    key === 'gender' && (genderOptions?.find(g => g.value === value)?.label || value)

  const containerRef = useRef()
  const [isOpenPlatformChip, setOpenPlatformChip] = useState(false)

  useOutsideClick({
    ref: containerRef,
    callback: () => setOpenPlatformChip(false),
  })

  const handleChipClick = useCallback(() => {
    // If suggestion, add it to the search
    if (isSuggestion) {
      addSuggestions(searchParam)
    } else if (keyName === 'index') {
      // Toggle platform dropdown
      setOpenPlatformChip(prev => !prev)
    }
  }, [isSuggestion, keyName, searchParam, addSuggestions])

  const changeSocialPlatform = useCallback(
    (e, platform) => {
      if (platform === selectedPlatform) return
      dispatch([
        changeAppSocialPlatform(platform),
        updateSearch({
          newValuesObj: {
            index: platform?.toLowerCase(),
          },
        }),
      ])
    },
    [dispatch, selectedPlatform]
  )

  const labelStyles = useMemo(
    () => ({
      color: '#442424',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '13px',
      fontFamily: 'Inter',
    }),
    []
  )

  const chipStyles = useMemo(
    () => ({
      backgroundColor: isSuggestion ? '#FFFFFF' : '#F6DBDB',
    }),
    [isSuggestion]
  )

  const iconStyles = useMemo(
    () => ({
      fill: '#442424',
      width: '15px',
      height: '15px',
      '.MuiChip-deleteIcon': {
        margin: '0 8px 0 -8px',
      },
    }),
    []
  )

  // If we already have the same param in our query, skip rendering
  if (isFindTheSameParams) return null

  // Mapping for how to label each param
  const labels = {
    textInput: labelStrings.try,
    lookalike: labelStrings.createLookalike,
    search: `${labelStrings.previousSearch}:`,
    growthRate: `${labelStrings.growthRate}: > ${value}%`,
    ageGroup: `${labelStrings.ageGroup}: ${
      currentQuery?.ageGroup?.toString().split(',')[0] === value ? 'from' : 'to'
    } ${value}`,
    engagementRate: `${labelStrings.engagementRate}: > ${value}%`,
    followerMin: `${
      currentPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name
        ? labelStrings.subscribers
        : labelStrings.followers
    }: min. ${pretifyBigNum(Number(value))}`,
    followerMax: `${
      currentPlatform === SOCIAL_PLATFORMS_NAMES.youtube.name
        ? labelStrings.subscribers
        : labelStrings.followers
    }: max. ${pretifyBigNum(Number(value))}`,
    viewsMin: `${isContentType ? 'Reach: min.' : 'Views/Post: min.'} ${pretifyBigNum(
      Number(value)
    )}`,
    viewsMax: `${isContentType ? 'Reach: max.' : 'Views/Post: max.'} ${pretifyBigNum(
      Number(value)
    )}`,
    playsMin: `Plays/Video: min. ${pretifyBigNum(Number(value))}`,
    playsMax: `Plays/Video: max. ${pretifyBigNum(Number(value))}`,
    reelPlaysMin: `Plays/Reel: min. ${pretifyBigNum(Number(value))}`,
    reelPlaysMax: `Plays/Reel: max. ${pretifyBigNum(Number(value))}`,
    shortsPlaysMax: `Views/Short: ${pretifyBigNum(Number(value))}`,
    shortsPlaysMin: `Views/Short: ${pretifyBigNum(Number(value))}`,
    language: languageLabel,
    keywords: `${labelStrings.keywords}: ${value}`,
    brandMentions: `Brand Mentions: ${value}`,
    creatorCollaborations: `Creator Collaborations: ${value}`,
    categories: `${labelStrings.categories}: ${value}`,
    audienceCountries: `Audience Country: ${value}`,
    audienceGender: `Audience Gender: ${value}`,
    audienceRealPeople: `Audience Real People: ${value}%`,
    audienceAgeRanges: `Audience Age: ${
      currentQuery?.audienceAgeRanges?.toString().split(',')[0] === value ? 'from' : 'to'
    } ${value}`,
    gender: `Gender: ${genderLabel}`,
    radiusKM: `Location Radius: ${value}`,
    placesId: `Location: ${value}`,
    country: capitalizeFirstLetter(value),
    city: capitalizeFirstLetter(value),
    contentId: `${labelStrings.similarContent}: ${value}`,
    fileUrl: `Upload: ${
      value && typeof value === 'string'
        ? value.replace('https://creator-assets-infludata.s3.eu-central-1.amazonaws.com/', '')
        : ''
    }`,
  }

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        width: 'fit-content',
        '& .MuiChip-icon': {
          color: '#442424 !important',
          fill: '#442424 !important',
          ml: '6px !important',
        },
        '& .MuiChip-label': { pl: '4px' },
      }}
    >
      <Chip
        size="small"
        sx={chipStyles}
        icon={searchParamsIcons[key]}
        label={
          <Stack direction="row" alignItems="center" spacing={0.5} sx={{ p: '2px' }}>
            <Typography sx={labelStyles}>
              {labels
                ? labels[searchParam?.action]
                : isSuggestion
                ? `${searchParam?.action || searchParam?.key}:`
                : `${searchParamsName[keyName] || keyName}:`}
            </Typography>
            <Typography
              sx={{
                color: '#442424',
                fontWeight: 700,
                fontSize: '13px',
                lineHeight: '13px',
                fontFamily: 'Inter',
              }}
            >
              {labels[key] || searchParam?.label || value}
            </Typography>
          </Stack>
        }
        onClick={() => {
          setCurrentOpenSearchParam(keyName)
          return isSuggestion || keyName === 'index' ? handleChipClick() : undefined
        }}
        onDelete={() => {
          return keyName === 'index' || searchParam?.method
            ? handleChipClick()
            : removeSearchParamsFromQuery({
                key: keyName,
                value: {
                  default: defaultSearchQuery[keyName] || defaultAmountQueryValues[keyName],
                  currentVal: value,
                },
              })
        }}
        deleteIcon={
          keyName === 'index' ? (
            isOpenPlatformChip ? (
              <KeyboardArrowUpRoundedIcon sx={iconStyles} />
            ) : (
              <KeyboardArrowDownRoundedIcon sx={iconStyles} />
            )
          ) : isSuggestion ? (
            <AddRoundedIcon sx={iconStyles} />
          ) : (
            <CloseRoundedIcon sx={iconStyles} />
          )
        }
      />

      {keyName === 'index' && isOpenPlatformChip && (
        <Paper
          sx={{
            position: 'absolute',
            backgroundColor: '#F6DBDB',
            p: 1,
            width: '100%',
            minWidth: isBrandType ? '110px' : '150px',
            mt: 0.5,
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            animation: isOpenPlatformChip
              ? `${animationShowPlatformChip} 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`
              : `${animationHidePlatformChip} 2s ease-in-out 0s 1 normal forwards`,
            '& .MuiTab-root': {
              '&:last-child': {
                mr: '0 !important',
              },
            },
          }}
        >
          <ChipPlatforms
            platform={selectedPlatform || currentQuery?.index}
            changeSocialPlatform={changeSocialPlatform}
            isBrandType={isBrandType}
          />
        </Paper>
      )}
    </Box>
  )
}

export default SearchChip
