import React from 'react'
import useTranslation from '../../../../../localization/useTranslation'
import { Box, Autocomplete, Chip, TextField } from '@mui/material'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../../constants/appSettings'

export const KeywordsFilter = props => {
  const {
    selectedPlatform,
    query,
    handlersMap,
    // permissions,
    suggestionsKeywords,
  } = props || {}

  const { labelStrings } = useTranslation()
  const queryKeywordsValue = query?.keywords?.toString() || query?.postCaptions?.toString()
  const keywordValuesToArray = !!queryKeywordsValue?.length ? queryKeywordsValue?.split(',') : []

  const onChange = (event, values) => {
    const currValue = typeof values === 'number' ? values?.toString() : values
    const keywordValues = currValue?.join(',')
    selectedPlatform === SOCIAL_PLATFORMS_NAMES.twitch.name
      ? handlersMap?.postCaptions(keywordValues)
      : handlersMap?.keywords(keywordValues)
  }

  return (
    <Box
      sx={{
        minWidth: '250px',
        '& .MuiOutlinedInput-root': {
          color: '#6B4545',
          outline: 'unset',
          '& fieldset': {
            borderColor: '#BC9797',
          },
          '&:hover fieldset': {
            borderColor: '#784E4E',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#784E4E',
          },
        },
        '& .MuiInputLabel-root': {
          color: '#6B4545',
          '&.Mui-focused': {
            color: '#6B4545',
          },
        },
      }}
    >
      <Autocomplete
        sx={{ fontWeight: 'bold' }}
        size="small"
        clearIcon={false}
        options={suggestionsKeywords}
        getOptionLabel={option => option?.join(',')}
        freeSolo
        multiple
        filterSelectedOptions
        onChange={onChange}
        value={keywordValuesToArray}
        renderTags={(value, props) => {
          return value.map((option, index) => (
            <Chip
              size="small"
              label={option}
              variant="contained"
              sx={{
                border: option?.startsWith('-') ? '1px solid #FF403B' : '1px solid #49D59C',
                color: '#442424',
                fontWeight: 'bold',
                backgroundColor: '#F6DBDB',
                '& svg': {
                  fill: '#6B4545',
                },
              }}
              {...props({ index })}
            />
          ))
        }}
        renderInput={params => (
          <TextField
            fullWidth
            autoFocus
            sx={{
              '& .MuiFormLabel-root': {
                fontWeight: 'bold',
                color: '#784E4E',
                '&.Mui-focused': { color: '#784E4E' },
              },
            }}
            label={labelStrings.typeYourKeyword}
            {...params}
          />
        )}
      />
    </Box>
  )
}
