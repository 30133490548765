import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Stack,
  Box,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  Chip,
  Paper,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  Avatar,
  Typography,
  Divider,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { TwitchPlatformIcon } from '../../../../components/common/icons'
import useTranslation from '../../../../localization/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import {
  recentSearchArraySelector,
  selectedPlatformSelector,
  suggestedCreatorsArraySelector,
  userCredsSelector,
} from '../../../../redux/selectors'
import { SOCIAL_PLATFORMS_NAMES } from '../../../../constants/appSettings'
import {
  changeSuggestionsData,
  getSearchBySmartText,
  resetSearchResults,
  updateSearch,
  updateSearchTypeStatus,
  uploadFileGetUrlForSimilarContent,
} from '../../../../redux/ducks/searchDucks'
import { searchContentTypes } from '../../../../constants/search'
import { LookalikeHistoryIcon, NavbarLookaLikeIcon, SuggestionContentIcon } from './icons'
import { toast } from 'react-toastify'
import useOutsideClick from '../../../../hooks/useOutsideClick'
import { RecentSearch } from './RecentSearch'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import { Suggestions } from './Suggestions'
import {
  buildLookalike,
  changeAdditionalData,
  deleteLookalikeList,
  getLookalikeLists,
  getSuggestedUsersById,
} from '../../../../redux/ducks/exploreDucks'
import { LookalikeAiModal } from '../../Explore/LookalikeAi/LookalikeAiModal'
import { cancelAllFetches } from '../../../components/search-sidebar/searchSidebar/searchbarTipsFetches'
import urlQueryService from '../../../../services/urlQueryService'
import { defaultSearchQuery } from '../utils'
import { markNotificationsAsSeen } from '../../../../redux/ducks/startupActions'
import { useLocation } from 'react-router-dom'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { TikTokSwitchIcon } from '../../../components/nav-section/CreatorsSuggestions/icons'
import AddProfileToInfludata from '../../../components/add-profile-to-infludata/AddProfileToInfludata'

const gradientColors = {
  [SOCIAL_PLATFORMS_NAMES.instagram.name]: {
    start: '#FF6B00',
    end: '#FF3B99',
    static: '#FF5500',
  },
  [SOCIAL_PLATFORMS_NAMES.tiktok.name]: {
    start: '#00F2EA',
    end: '#02BDC9',
    static: '#4ad1db',
  },
  [SOCIAL_PLATFORMS_NAMES.youtube.name]: {
    start: '#EA3323',
    end: '#bd1a2e',
    static: '#EA3323',
  },
  [SOCIAL_PLATFORMS_NAMES.twitch.name]: {
    start: '#874cf6',
    end: '#ceb4ff',
    static: '#874CF6',
  },
}

const _RECENT_TYPE = 'RECENT_TYPE'
const _SUGGESTIONS_TYPE = 'SUGGESTIONS_TYPE'

export const SearchSmartBar = ({
  inputValue,
  setInputValue,
  onGetSearchBySmartText,
  searchType,
  dataSuggestions,
  clearSearchMain,
  openModalLookalikeAi,
  setOpenModalLookalikeAi,
  currentQuery,
  tempBrandMentionsData,
  lookalikeId,
  setShowAddUserModal,
}) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()

  const params = new URLSearchParams(search)
  const isActionLookalike = params.get('action') === 'lookalike'
  const isLookalikeId = params.get('lookalikeId')
  const promptSuggestionValueFromQuery = params.get('promptSuggestion')
  const platformIndexFromQuery = params.get('index')

  const suggestedCreatorsArray = useSelector(suggestedCreatorsArraySelector)
  const recentSearchArray = useSelector(recentSearchArraySelector)
  const platform = useSelector(selectedPlatformSelector)
  const { notifications } = useSelector(userCredsSelector)
  const { showFlashingLookalikeIcon } = notifications || {}

  const inputRef = useRef()
  const fileInputRef = useRef(null)
  const recentSearchRef = useRef(null)

  // Create a reference for the debounce timer
  const debounceTimerRef = useRef(null)

  const hasSpace = inputValue?.includes(' ')

  const [dataFromLookalike, setDataFromLookalike] = useState({
    userList: [],
    platform: '',
  })
  const [isShowItemsList, setIsShowItemsList] = useState({
    show: false,
    type: '',
  })
  const [openLookalikeModalFromSuggestions, setOpenLookalikeModalFromSuggestions] = useState(false)
  const [userSuggestions, setUserSuggestions] = useState([])
  const [showUserSuggestions, setShowUserSuggestions] = useState(false)
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false)

  useOutsideClick({
    ref: recentSearchRef,
    callback: () => {
      setIsShowItemsList({
        show: false,
        type: '',
      })
      setOpenLookalikeModalFromSuggestions(false)
      setShowUserSuggestions(false)
    },
  })

  const toInputFocus = () => {
    inputRef?.current?.focus()
    onGetSearchBySmartText(inputValue)
    setShowUserSuggestions(false)
  }

  const onChangeInputValue = e => {
    const value = e.target.value
    setInputValue(value)

    // For all queries with 2+ letters, show the search dropdown
    if (value.trim().length > 1) {
      setShowUserSuggestions(true)

      // Only fetch user suggestions for simple queries (2 words or less)
      const words = value.trim().split(/\s+/)
      if (words.length <= 2) {
        setIsLoadingSuggestions(true)

        // Debounce API requests - cancel previous timer if typing continues
        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current)
        }

        // Wait 100ms before making the API request
        debounceTimerRef.current = setTimeout(() => {
          fetchUserSuggestions(value)
          debounceTimerRef.current = null
        }, 100)
      } else {
        // For complex queries, clear suggestions but keep dropdown open
        setUserSuggestions([])
        setIsLoadingSuggestions(false)
      }
    } else {
      // For empty or single-letter queries, hide everything
      setUserSuggestions([])
      setShowUserSuggestions(false)
      setIsLoadingSuggestions(false)
    }
  }

  const fetchUserSuggestions = async query => {
    try {
      const response = await fetch(
        `/api/getUserSuggestionByLetters?letters=${encodeURIComponent(query)}}`
      )
      if (response.ok) {
        const data = await response.json()

        // Sort results to prioritize exact username matches
        const sortedData = [...data].sort((a, b) => {
          const aExactMatch = a.username?.toLowerCase() === query.toLowerCase()
          const bExactMatch = b.username?.toLowerCase() === query.toLowerCase()

          if (aExactMatch && !bExactMatch) return -1 // a comes first
          if (!aExactMatch && bExactMatch) return 1 // b comes first
          return 0 // keep original order
        })

        setUserSuggestions(sortedData)
      }
      setIsLoadingSuggestions(false)
    } catch (error) {
      console.error('Error fetching user suggestions:', error)
      setIsLoadingSuggestions(false)
    }
  }

  const handleProfileClick = user => {
    setInputValue(user.username)
    setShowUserSuggestions(false)
    
    // Open profile page directly without calling getSearchBySmartText
    if (user._id) {
      const profileUrl = `/profile/${user._id}`
      history.push(profileUrl)
    }
  }

  const renderPlatformIcon = _index => {
    if (_index && _index.toLowerCase().includes('instagram')) {
      return <InstagramIcon sx={{ fontSize: '22px' }} />
    } else if (_index && _index.toLowerCase().includes('tiktok')) {
      return <TikTokSwitchIcon width={20} height={20} color="#4ad1db" />
    } else if (_index && _index.toLowerCase().includes('youtube')) {
      return <YouTubeIcon sx={{ fontSize: '22px' }} />
    } else if (_index && _index.toLowerCase().includes('twitch')) {
      return <TwitchPlatformIcon userindex={_index} color={'#874CF6'} />
    }

    return null
  }

  const formatFollowerCount = count => {
    if (!count) return ''

    // Format in K, M notation
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`
    } else if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`
    } else {
      return count.toString()
    }
  }

  const getInitialSearchBySmartText = () => {
    if (searchType !== searchContentTypes.CREATOR) return
    const promptValueFromQuery =
      decodeURIComponent(promptSuggestionValueFromQuery) === 'null'
        ? ''
        : decodeURIComponent(promptSuggestionValueFromQuery)
    // @ts-ignore

    dispatch([
      getSearchBySmartText({
        inputParam: promptValueFromQuery?.length ? promptValueFromQuery : null,
        platform: promptValueFromQuery?.length ? platformIndexFromQuery : null,
        method: promptValueFromQuery?.length ? 'textInput' : 'init',
        withoutSearchLoading: true,
        isInitial: true,
      }),
      changeSuggestionsData({}),
    ])
  }

  const clearSearch = () => {
    setInputValue('')
    setUserSuggestions([])
    setShowUserSuggestions(false)
    getInitialSearchBySmartText()
    clearSearchMain()
  }

  useEffect(() => {
    if (!dataSuggestions?.length) {
      getInitialSearchBySmartText()
    }
  }, [])

  const placeholderLabels = {
    [searchContentTypes.CREATOR]: labelStrings.searchSuggestions.suggestionsPlaceholder,
    [searchContentTypes.BRAND]: labelStrings.searchSuggestions.suggestionsBrandPlaceholder,
    [searchContentTypes.CONTENT]: labelStrings.searchSuggestions.suggestionsContentPlaceholder,
  }

  const onRecentSearch = () => {
    setIsShowItemsList(prev => ({
      show: prev.type === _RECENT_TYPE ? !prev.show : true,
      type: prev.type === _RECENT_TYPE ? '' : _RECENT_TYPE,
    }))
  }

  const onSuggestions = () => {
    dispatch([getLookalikeLists(), markNotificationsAsSeen({ location: 'lookalikes_tab' })])
    setIsShowItemsList(prev => ({
      show: prev.type === _SUGGESTIONS_TYPE ? !prev.show : true,
      type: prev.type === _SUGGESTIONS_TYPE ? '' : _SUGGESTIONS_TYPE,
    }))
    setOpenLookalikeModalFromSuggestions(true)
  }

  const onUploadFileGetUrlForSimilarContent = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async event => {
    const file = event.target.files?.[0]

    // Validate file type
    const allowedFileTypes = ['image/jpeg', 'image/png']
    if (!allowedFileTypes?.includes(file?.type)) {
      toast.error(`Only JPG, JPEG, and PNG files are allowed.`, {
        theme: 'colored',
      })
      return
    }

    // Validate file size (5 MB = 5 * 1024 * 1024 bytes)
    const maxSizeInBytes = 5 * 1024 * 1024
    if (file.size > maxSizeInBytes) {
      toast.error(`File size must not exceed 5 MB.`, { theme: 'colored' })
      return
    }

    if (!file) return
    dispatch(uploadFileGetUrlForSimilarContent({ file, history }))
    event.target.value = null
  }

  const onApplySearch = data => {
    const { query, formattedString } = data || {}
    setInputValue(formattedString)
    setIsShowItemsList({
      show: false,
      type: '',
    })
    dispatch(
      updateSearch({
        newValuesObj: {
          ...query,
        },
      })
    )
  }

  const onHandleConfirmLookalike = ({ label }) => {
    dispatch(
      // @ts-ignore
      buildLookalike({
        ...dataFromLookalike,
        label,
        userList: [...new Set(dataFromLookalike.userList)],
      })
    )
    setOpenLookalikeModalFromSuggestions(false)
  }

  const onOpenModalLookalikeAi = () => {
    setOpenModalLookalikeAi(true)
    setIsShowItemsList({
      show: false,
      type: '',
    })
  }

  const onOpenSuggestions = data => {
    setInputValue('')
    cancelAllFetches()
    // @ts-ignore
    dispatch([
      updateSearchTypeStatus(searchContentTypes.CREATOR),
      resetSearchResults(),
      changeSuggestionsData({}),
      changeAdditionalData(data),
      getSuggestedUsersById({ queryId: data?.id }),
    ])
    localStorage?.removeItem('searchQuery')
    urlQueryService.setNewSearchQuery({
      latestQuery: defaultSearchQuery,
    })
    setIsShowItemsList({
      show: false,
      type: '',
    })
    history.replace(`/search?action=lookalike&lookalikeId=${data?.id}`)
  }

  const onDeleteSuggestionList = (e, data) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(deleteLookalikeList({ id: data?.id }))
    setIsShowItemsList({
      show: false,
      type: '',
    })

    if (data.id === lookalikeId) {
      clearSearch()
    }
  }

  const onCloseModalLookalikeAi = () => {
    setOpenModalLookalikeAi(false)
    setOpenLookalikeModalFromSuggestions(false)
  }

  const renderBottomInputList = {
    [_RECENT_TYPE]: <RecentSearch data={recentSearchArray} onApplySearch={onApplySearch} />,
    [_SUGGESTIONS_TYPE]: (
      <Suggestions
        data={suggestedCreatorsArray}
        platform={platform}
        onOpenModalLookalikeAi={onOpenModalLookalikeAi}
        onOpenSuggestions={onOpenSuggestions}
        onDeleteSuggestionList={onDeleteSuggestionList}
      />
    ),
  }

  useEffect(() => {
    if (isActionLookalike && isLookalikeId) {
      onOpenSuggestions({ id: isLookalikeId })
    }
  }, [isActionLookalike, isLookalikeId])

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ pt: '10px' }}>
        <Box ref={recentSearchRef} sx={{ maxWidth: '809px', width: '100%', position: 'relative' }}>
          <TextField
            disabled={false}
            inputRef={inputRef}
            size="small"
            variant="outlined"
            value={inputValue}
            onChange={onChangeInputValue}
            placeholder={placeholderLabels[searchType]}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onGetSearchBySmartText(inputValue)
                setShowUserSuggestions(false)
              }
            }}
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: 'off',
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    disabled={false}
                    sx={{ width: '32px', height: '32px', color: '#6B4545' }}
                    onClick={toInputFocus}
                  >
                    <SearchIcon
                      sx={{
                        fill: gradientColors[platform].static,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Stack direction="row" alignItems="center" spacing={0}>
                    <Tooltip title={labelStrings.searchSuggestions.recentSearchTooltip}>
                      <div>
                        <IconButton
                          disabled={!recentSearchArray?.length}
                          disableRipple
                          sx={{
                            width: '32px',
                            height: '32px',
                            '&:hover': { opacity: 0.7 },
                            ...(isShowItemsList.type === _RECENT_TYPE && {
                              background:
                                'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)',
                              '& svg': {
                                fill: '#fff',
                                '& path': {
                                  fill: '#fff',
                                },
                              },
                            }),
                            borderRadius: '9px',
                          }}
                          onClick={onRecentSearch}
                        >
                          <LookalikeHistoryIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={labelStrings.searchSuggestions.lookalikeTooltip}>
                      <div>
                        <IconButton
                          disabled={false}
                          disableRipple
                          sx={{
                            width: '38px',
                            height: '38px',
                            borderRadius: '9px',
                            background:
                              showFlashingLookalikeIcon ||
                              isShowItemsList.type === _SUGGESTIONS_TYPE
                                ? 'linear-gradient(87deg, #FFB904 1.15%, #FF6B00 30.19%, #FF403B 57.11%, #FF3B99 100%)'
                                : undefined,
                            animation: showFlashingLookalikeIcon
                              ? 'flashing 2s infinite' // Add animation
                              : undefined,
                            '& svg': {
                              fill:
                                showFlashingLookalikeIcon ||
                                isShowItemsList.type === _SUGGESTIONS_TYPE
                                  ? '#fff'
                                  : undefined,
                              '& path': {
                                fill:
                                  showFlashingLookalikeIcon ||
                                  isShowItemsList.type === _SUGGESTIONS_TYPE
                                    ? '#fff'
                                    : undefined,
                              },
                            },
                            '&:hover': { opacity: 0.7 },
                          }}
                          onClick={onSuggestions}
                        >
                          <NavbarLookaLikeIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                      <Tooltip title={labelStrings.searchSuggestions.uploadTooltip}>
                        <div>
                          <IconButton
                            disabled={false}
                            disableRipple
                            sx={{
                              width: '35px',
                              height: '35px',
                              '&:hover': { opacity: 0.7 },
                            }}
                            onClick={onUploadFileGetUrlForSimilarContent}
                          >
                            <SuggestionContentIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </>
                  </Stack>
                </InputAdornment>
              ),
            }}
            sx={{
              width: '100%',
              pointerEvents: 'auto',
              '.MuiInputBase-root': {
                borderRadius: '10px',
                height: '46px',
                color: gradientColors[platform].static,
                backgroundColor: 'white',
                fontSize: '16px',
                fontWeight: 600,
                fontFamily: `Inter, sans-serif`,
              },
              '& .MuiOutlinedInput-root': {
                outline: 'unset',
                '& fieldset': {
                  borderColor: gradientColors[platform].static,
                  borderRadius: '10px',
                },
                '&:hover fieldset': {
                  borderWidth: '2px',
                  borderColor: gradientColors[platform].static,
                  borderRadius: '10px',
                },
                '&.Mui-focused fieldset': {
                  borderWidth: '2px',
                  borderColor: gradientColors[platform].static,
                  borderRadius: '10px',
                },
              },
              '& input::placeholder': {
                fontSize: '16px',
                fontWeight: 400,
                fontFamily: `Inter, sans-serif`,
                color: '#BC9797',
              },
            }}
          />
          <Chip
            size="small"
            label={labelStrings.searchSuggestions.promptsByChatGPT}
            sx={{
              position: 'absolute',
              top: '-10px',
              left: '15px',
              fontSize: '9px',
              fontFamily: `Inter, sans-serif`,
              color: '#442424',
              height: '18px',
              fontWeight: 700,
              backgroundColor: '#F6DBDB',
            }}
          />
          {/* User suggestions dropdown */}
          {showUserSuggestions && inputValue.trim().length > 1 && !isShowItemsList.show && (
            <Paper
              elevation={3}
              sx={{
                position: 'absolute',
                top: '52px',
                left: 0,
                backgroundColor: 'white',
                width: '100%',
                height: 'auto',
                maxHeight: '350px',
                zIndex: 999,
                borderRadius: '10px',
              }}
            >
              <Scrollbar sx={{ maxHeight: '350px' }}>
                <List sx={{ p: 0 }}>
                  {isLoadingSuggestions ? (
                    <ListItem sx={{ justifyContent: 'center', py: 2 }}>
                      <CircularProgress size={24} sx={{ color: gradientColors[platform].static }} />
                    </ListItem>
                  ) : inputValue.trim().length > 1 ? (
                    <>
                      {!userSuggestions.some(
                        user => user?.username?.toLowerCase() === inputValue.toLowerCase()
                      ) && (
                        <ListItem
                          button
                          onClick={() => onGetSearchBySmartText(inputValue)}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#f5f5f5',
                            },
                            py: 1,
                            cursor: 'pointer',
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                width: 40,
                                height: 40,
                                bgcolor: '#FAEBEB',
                                color: gradientColors[platform]?.static,
                              }}
                            >
                              <SearchIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography
                                sx={{ fontWeight: 600, fontFamily: 'Inter', color: '#442424' }}
                              >
                                {`Discover creators based on "${inputValue}"`}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="body2"
                                sx={{ fontFamily: 'Inter', color: '#6B4545' }}
                              >
                                Press Enter or click to search
                              </Typography>
                            }
                          />
                        </ListItem>
                      )}
                      {userSuggestions.map((user, idx) => (
                        <ListItem
                          key={user._id || idx}
                          button
                          onClick={() => handleProfileClick(user)}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#f5f5f5',
                            },
                            py: 1,
                            cursor: 'pointer',
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              src={user.profilePicURL || ''}
                              alt={user.username}
                              sx={{
                                width: 40,
                                height: 40,
                                border: `1px solid ${gradientColors[platform].static}`,
                              }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Box
                                    sx={{
                                      // mr: 0.5,
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: gradientColors[user._index?.toUpperCase()].static,
                                      '& span': { m: 0 },
                                    }}
                                  >
                                    {renderPlatformIcon(user._index)}
                                  </Box>
                                  <Stack>
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontFamily: 'Inter',
                                        color: '#442424',
                                      }}
                                    >
                                      {user.username}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="#6B4545"
                                      sx={{ fontFamily: 'Inter' }}
                                    >
                                      {user.displayName || ''}
                                    </Typography>
                                  </Stack>
                                </Stack>

                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 600, fontFamily: 'Inter', color: '#6B4545' }}
                                >
                                  {user._index &&
                                  user._index.toLowerCase().includes('youtube') &&
                                  user.subscribers
                                    ? formatFollowerCount(user.subscribers)
                                    : user.followers
                                    ? formatFollowerCount(user.followers)
                                    : ''}
                                </Typography>
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                      {!hasSpace && (
                        <Stack spacing={1} sx={{ padding: 2 }}>
                          <Divider />
                          <AddProfileToInfludata
                            onAddUserClick={setShowAddUserModal}
                            userindex={platform?.toLowerCase()}
                            fromSearchSmartBar={true}
                          />
                        </Stack>
                      )}
                    </>
                  ) : (
                    <ListItem>
                      <ListItemText primary="No matching profiles found" />
                    </ListItem>
                  )}
                </List>
              </Scrollbar>
            </Paper>
          )}
          {isShowItemsList.show && (
            <Paper
              elevation={3}
              sx={{
                position: 'absolute',
                top: '52px',
                left: 0,
                backgroundColor: 'white',
                width: '100%',
                height: 'auto',
                maxHeight: '350px',
                zIndex: 999,
                borderRadius: '10px',
              }}
            >
              <Scrollbar sx={{ maxHeight: '350px', p: 1 }}>
                {renderBottomInputList[isShowItemsList.type]}
              </Scrollbar>
            </Paper>
          )}
        </Box>
        <Button
          sx={{
            minWidth: '135px',
            height: '46px',
            borderRadius: '10px',
            borderWidth: '1px',
            borderColor: gradientColors[platform].static,
            color: gradientColors[platform].static,
            backgroundColor: 'white',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 600,
            fontFamily: `Inter, sans-serif`,
            '&:hover': {
              borderWidth: '1px',
              borderColor: gradientColors[platform].static,
            },
          }}
          disabled={false}
          variant="outlined"
          onClick={clearSearch}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span>{labelStrings.searchSuggestions.resetSearch}</span>
            <span style={{ fontSize: '12px', lineHeight: '16px' }}>
              {labelStrings.searchSuggestions.backToDashboard}
            </span>
          </div>
        </Button>
      </Stack>
      <LookalikeAiModal
        openModalLookalikeAi={openModalLookalikeAi}
        onCloseModalLookalikeAi={onCloseModalLookalikeAi}
        onHandleConfirmLookalike={onHandleConfirmLookalike}
        setDataFromLookalike={setDataFromLookalike}
        currentQuery={currentQuery}
        openLookalikeModalFromSuggestions={openLookalikeModalFromSuggestions}
        tempBrandMentionsData={tempBrandMentionsData}
      />
    </>
  )
}
