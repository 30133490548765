import React, { useEffect, useState } from "react"
import useTranslation from "../../../../../localization/useTranslation"
import { Box, Slider, Stack, Typography } from "@mui/material"
import { infludataThemes } from "../../../../../themes/infludataThemes"
// import { SOCIAL_PLATFORMS_NAMES } from "../../../../../constants/appSettings"

export const GrowthRateFilter = (props) => {
  const { selectedPlatform, query, handlersMap, permissions } = props || {}

  const { labelStrings } = useTranslation()

  const [localSliderValue, setLocalSliderValue] = useState(0)

  const handleChange = (event, newValue) => {
    setLocalSliderValue(newValue)
  }

  const onChangeQueryValues = (e, value) => {
    // selectedPlatform === SOCIAL_PLATFORMS_NAMES.twitch.name
    handlersMap.growthRate(value)
  }

  useEffect(() => {
    setLocalSliderValue(query?.growthRate)
  }, [])

  return (
    <Stack sx={{ px: 2, py: 1, overflow: 'hidden' }}>
      <Typography
        sx={{
          color: "#784E4E",
          fontWeight: 700,
          fontSize: "11px",
          textTransform: "uppercase",
          fontFamily: "Inter",
        }}
      >
        {labelStrings.minGrowthRate} (30 {labelStrings.days})
      </Typography>
      <Box>
        <Slider
          sx={{
              width: "100%",
              height: "5px",
              borderRadius: "8px",
              color: infludataThemes[selectedPlatform]?.platformColor,
              "& .MuiSlider-valueLabelOpen": {
                color: infludataThemes[selectedPlatform]?.platformColor,
                fontWeight: "bold",
              },
              '& .MuiSlider-rail': {
                color: infludataThemes[selectedPlatform]?.platformColor,
                opacity: 1
              },
              "& .MuiSlider-track": {
                backgroundColor: `#F3DBDB`,
                border: `1px solid #F3DBDB`,
              },
              "& .MuiSlider-valueLabel": {
                background: "unset",
                transformOrigin: "bottom left",
                "&::before": { display: "none" },
                "&.MuiSlider-valueLabelOpen": {
                  transform: "translate(60%, -50%) rotate(-225deg) scale(1)",
                },
                "& > *": {
                  transform: "rotate(-135deg)",
                },
              },
            }}
          value={localSliderValue}
          onChange={handleChange}
          onChangeCommitted={onChangeQueryValues}
          valueLabelDisplay='on'
          valueLabelFormat={(val) => (val === 0 ? "Off" : `≥ ${val}%`)}
          min={0}
          max={15}
        />
      </Box>
    </Stack>
  )
}
