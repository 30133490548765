import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Scrollbar from '../../../components/scrollbar/Scrollbar'
import useTranslation from '../../../../localization/useTranslation'
import { inputSymbol } from '../../keywords-mentions-manager/utils'
import useOutsideClick from '../../../../hooks/useOutsideClick'

export const StepSeven = props => {
  const { setValue, getValues, topHashtags } = props

  const { labelStrings, currentLang } = useTranslation()

  const selectOptions = [
    { value: 'mention', label: labelStrings.mention },
    { value: 'hashtag', label: '# Hashtag' },
    { value: 'text', label: labelStrings.freeText },
    { value: 'url', label: 'Link/Url (also parts)' },
  ]

  const [type, setType] = useState('hashtag')
  const [typeName, setTypeName] = useState('')
  const [typeNames, setTypeNames] = useState([])

  const [isFocused, setIsFocused] = useState(false)
  const containerRef = useRef(null)

  const valueFromFormState = getValues('trackedMetrics')

  const handleKeyDown = event => {
    if (event.key === '@' || event.key === '#' || event.key === ' ') {
      event.preventDefault()
    }
  }

  const onChangeType = event => {
    const { target } = event
    setType(target.value)
  }

  const onChangeTypeName = event => {
    const { target } = event
    setTypeName(target.value)
  }

  const onAddTypeNames = () => {
    const updatedValue = [...typeNames, { type, value: typeName }]
    setTypeNames(updatedValue)
    setValue('trackedMetrics', updatedValue)
    setTypeName('')
  }

  const addSuggestionsHashTags = hashTagName => {
    const updatedValue = [...typeNames, { type: 'hashtag', value: hashTagName }]
    setTypeNames(updatedValue)
    setValue('trackedMetrics', updatedValue)
    setIsFocused(false)
    setTypeName('')
  }

  const onRemoveTypeName = removeItem => {
    const updatedValue = typeNames?.filter(
      item => item.value !== removeItem.value || item.type !== removeItem.type
    )
    setTypeNames(updatedValue)
    setValue('trackedMetrics', updatedValue)
  }

  useEffect(() => {
    if (!typeNames?.length && valueFromFormState?.length) {
      const trackedMentionsValue = valueFromFormState?.map(el => ({
        type: 'mention',
        value: el.username || el.value,
      }))

      setTypeNames(trackedMentionsValue)
    }
  }, [valueFromFormState?.length])

  const handleFocus = () => {
    setIsFocused(true)
  }

  useOutsideClick({ ref: containerRef, callback: () => setIsFocused(false) })

  return (
    <Stack spacing={1.5} alignItems="center">
      <Typography
        align="center"
        component="label"
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          px: 4,
          whiteSpace: 'pre-line',
          mb: 1,
        }}
      >
        {labelStrings.createCampaign.steps.seven.label}
      </Typography>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%', px: 4 }}>
        <FormControl sx={{ minWidth: 127 }}>
          <InputLabel>{labelStrings.selectType}</InputLabel>
          <Select name="type" label={labelStrings.selectType} onChange={onChangeType} value={type}>
            {selectOptions?.map(currency => (
              <MenuItem key={currency.value} value={currency.value}>
                {currency.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack spacing={1} direction="row" alignItems="center" sx={{ width: '100%' }}>
          <Box ref={containerRef} sx={{ position: 'relative' }}>
            <TextField
              label={labelStrings.createCampaign.steps.seven.fieldLabel}
              variant="outlined"
              value={typeName}
              onChange={onChangeTypeName}
              onFocus={handleFocus}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{inputSymbol[type]}</InputAdornment>
                ),
                onKeyDown: handleKeyDown,
              }}
              inputProps={{
                maxLength: 60,
              }}
              sx={{ width: '100%' }}
            />
            {isFocused && !!topHashtags?.length && type === 'hashtag' && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '60px',
                  zIndex: 2,
                  backgroundColor: 'white',
                  boxShadow: `0 0 2px 0 rgba(145, 158, 171, 0.2),0 12px 24px -4px rgba(145, 158, 171, 0.12)`,
                  borderRadius: '9px',
                  py: 1,
                }}
              >
                <Scrollbar
                  sx={{
                    maxHeight: '180px',
                    width: '340px',
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{ fontWeight: 'bold', fontSize: '12px', pb: 1 }}
                  >
                    {labelStrings.createCampaign.steps.seven.suggestionLabel}
                  </Typography>
                  <Stack
                    direction="row"
                    sx={{ width: '100%', flexWrap: 'wrap', gap: 1, px: 1 }}
                    justifyContent="start"
                  >
                    {topHashtags?.map((item, idx) => (
                      <Chip
                        disabled={typeNames.some(typeNameValue => {
                          return typeNameValue.value === item.tag
                        })}
                        sx={{
                          borderColor: 'transparent',
                          backgroundColor: '#F4F6F8',
                          py: 3.5,
                          px: 1,
                          width: 'fit-content',
                          fontWeight: 'bold',
                          '.MuiChip-avatar': { width: '36px', height: '36px', mr: 0.5 },
                          '&:hover': {
                            opacity: 0.7,
                            cursor: 'pointer',
                          },
                        }}
                        key={idx}
                        label={`#${item.tag}`}
                        variant="outlined"
                        onClick={() => addSuggestionsHashTags(item.tag)}
                      />
                    ))}
                  </Stack>
                </Scrollbar>
              </Box>
            )}
          </Box>

          <Button disabled={!type || !typeName} onClick={onAddTypeNames}>
            {labelStrings.createCampaign.add}
          </Button>
        </Stack>
      </Stack>
      <Scrollbar
        sx={{
          maxHeight: '250px',
          width: '525px',
        }}
      >
        <Stack
          direction="row"
          sx={{ width: '100%', flexWrap: 'wrap', gap: 1, px: 3.5 }}
          justifyContent="start"
        >
          {typeNames?.map((item, idx) => (
            <Chip
              sx={{
                borderColor: 'transparent',
                backgroundColor: '#F4F6F8',
                py: 3.5,
                px: 1,
                width: 'fit-content',
                fontWeight: 'bold',
                '.MuiChip-avatar': { width: '36px', height: '36px', mr: 0.5 },
              }}
              key={idx}
              label={
                item.type === 'mention' || item.type === 'hashtag'
                  ? `${inputSymbol[item.type]}${item.value}`
                  : item.value
                  ? item.value
                  : `@${item.username}`
              }
              variant="outlined"
              onDelete={() => onRemoveTypeName(item)}
            />
          ))}
        </Stack>
      </Scrollbar>
      <Typography
        sx={{
          color: '#637381',
          lineHeight: 1.5,
          fontSize: `0.75rem`,
          fontFamily: `Public Sans,sans-serif`,
          fontWeight: 400,
          textAlign: `center`,
          marginTop: 2,
          marginRight: `14px`,
          marginBottom: 0,
          marginLeft: `14px`,
          px: currentLang === 'de' ? 4 : 2,
        }}
      >
        {labelStrings.createCampaign.steps.seven.helperText}
      </Typography>
    </Stack>
  )
}
